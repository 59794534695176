import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.number.constructor.js";
var Group = {
  name: 'ColorCheckboxGroup',
  props: {
    defaultValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      values: [],
      options: []
    };
  },
  computed: {
    colors: function colors() {
      var colors = [];
      this.options.forEach(function (item) {
        if (item.sChecked) {
          colors.push(item.color);
        }
      });
      return colors;
    }
  },
  provide: function provide() {
    return {
      groupContext: this
    };
  },
  watch: {
    values: function values(value) {
      this.$emit('change', value, this.colors);
    }
  },
  methods: {
    handleChange: function handleChange(option) {
      if (!option.checked) {
        if (this.values.indexOf(option.value) > -1) {
          this.values = this.values.filter(function (item) {
            return item != option.value;
          });
        }
      } else {
        if (!this.multiple) {
          this.values = [option.value];
          this.options.forEach(function (item) {
            if (item.value != option.value) {
              item.sChecked = false;
            }
          });
        } else {
          this.values.push(option.value);
        }
      }
    }
  },
  render: function render(h) {
    var clear = h('div', {
      attrs: {
        style: 'clear: both'
      }
    });
    return h('div', {}, [this.$slots.default, clear]);
  }
};
export default {
  name: 'ColorCheckbox',
  Group: Group,
  props: {
    color: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function data() {
    return {
      sChecked: this.initChecked()
    };
  },
  computed: {},
  inject: ['groupContext'],
  watch: {
    'sChecked': function sChecked() {
      var value = {
        value: this.value,
        color: this.color,
        checked: this.sChecked
      };
      this.$emit('change', value);
      var groupContext = this.groupContext;

      if (groupContext) {
        groupContext.handleChange(value);
      }
    }
  },
  created: function created() {
    var groupContext = this.groupContext;

    if (groupContext) {
      groupContext.options.push(this);
    }
  },
  methods: {
    toggle: function toggle() {
      if (this.groupContext.multiple || !this.sChecked) {
        this.sChecked = !this.sChecked;
      }
    },
    initChecked: function initChecked() {
      var groupContext = this.groupContext;

      if (!groupContext) {
        return this.checked;
      } else if (groupContext.multiple) {
        return groupContext.defaultValues.indexOf(this.value) > -1;
      } else {
        return groupContext.defaultValues[0] == this.value;
      }
    }
  }
};