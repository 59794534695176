import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";

/**
 * 该插件可根据菜单配置自动生成 ANTD menu组件
 * menuOptions示例：
 * [
 *  {
 *    name: '菜单名称',
 *    path: '菜单路由',
 *    meta: {
 *      icon: '菜单图标',
 *      invisible: 'boolean, 是否不可见, 默认 false',
 *    },
 *    children: [子菜单配置]
 *  },
 *  {
 *    name: '菜单名称',
 *    path: '菜单路由',
 *    meta: {
 *      icon: '菜单图标',
 *      invisible: 'boolean, 是否不可见, 默认 false',
 *    },
 *    children: [子菜单配置]
 *  }
 * ]
 *
 * i18n: 国际化配置。系统默认会根据 options route配置的 path 和 name 生成英文以及中文的国际化配置，如需自定义或增加其他语言，配置
 * 此项即可。如：
 * i18n: {
 *   messages: {
 *     CN: {dashboard: {name: '监控中心'}}
 *     HK: {dashboard: {name: '監控中心'}}
 *   }
 * }
 **/
import Menu from 'ant-design-vue/es/menu';
import Icon from 'ant-design-vue/es/icon';
import fastEqual from 'fast-deep-equal';
import { getI18nKey } from '@/utils/routerUtil';
var Item = Menu.Item,
    SubMenu = Menu.SubMenu;
export default {
  name: 'IMenu',
  props: {
    options: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    i18n: Object,
    openKeys: Array
  },
  data: function data() {
    return {
      selectedKeys: [],
      sOpenKeys: [],
      cachedOpenKeys: []
    };
  },
  computed: {
    menuTheme: function menuTheme() {
      return this.theme == 'light' ? this.theme : 'dark';
    }
  },
  created: function created() {
    var _this = this;

    this.updateMenu();

    if (this.options.length > 0 && !this.options[0].fullPath) {
      this.formatOptions(this.options, '');
    } // 自定义国际化配置


    if (this.i18n && this.i18n.messages) {
      var messages = this.i18n.messages;
      Object.keys(messages).forEach(function (key) {
        _this.$i18n.mergeLocaleMessage(key, messages[key]);
      });
    }
  },
  watch: {
    options: function options(val) {
      if (val.length > 0 && !val[0].fullPath) {
        this.formatOptions(this.options, '');
      }
    },
    i18n: function i18n(val) {
      var _this2 = this;

      if (val && val.messages) {
        var messages = this.i18n.messages;
        Object.keys(messages).forEach(function (key) {
          _this2.$i18n.mergeLocaleMessage(key, messages[key]);
        });
      }
    },
    collapsed: function collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.sOpenKeys;
        this.sOpenKeys = [];
      } else {
        this.sOpenKeys = this.cachedOpenKeys;
      }
    },
    '$route': function $route() {
      this.updateMenu();
    },
    sOpenKeys: function sOpenKeys(val) {
      this.$emit('openChange', val);
      this.$emit('update:openKeys', val);
    }
  },
  methods: {
    renderIcon: function renderIcon(h, icon, key) {
      if (this.$scopedSlots.icon && icon && icon !== 'none') {
        var vnodes = this.$scopedSlots.icon({
          icon: icon,
          key: key
        });
        vnodes.forEach(function (vnode) {
          vnode.data.class = vnode.data.class ? vnode.data.class : [];
          vnode.data.class.push('anticon');
        });
        return vnodes;
      }

      return !icon || icon == 'none' ? null : h(Icon, {
        props: {
          type: icon
        }
      });
    },
    renderMenuItem: function renderMenuItem(h, menu) {
      var tag = 'router-link';
      var config = {
        props: {
          to: menu.fullPath
        },
        attrs: {
          style: 'overflow:hidden;white-space:normal;text-overflow:clip;'
        }
      };

      if (menu.meta && menu.meta.link) {
        tag = 'a';
        config = {
          attrs: {
            style: 'overflow:hidden;white-space:normal;text-overflow:clip;',
            href: menu.meta.link,
            target: '_blank'
          }
        };
      }

      return h(Item, {
        key: menu.fullPath
      }, [h(tag, config, [this.renderIcon(h, menu.meta ? menu.meta.icon : 'none', menu.fullPath), this.$t(getI18nKey(menu.fullPath))])]);
    },
    renderSubMenu: function renderSubMenu(h, menu) {
      var this_ = this;
      var subItem = [h('span', {
        slot: 'title',
        attrs: {
          style: 'overflow:hidden;white-space:normal;text-overflow:clip;'
        }
      }, [this.renderIcon(h, menu.meta ? menu.meta.icon : 'none', menu.fullPath), this.$t(getI18nKey(menu.fullPath))])];
      var itemArr = [];
      menu.children.forEach(function (item) {
        itemArr.push(this_.renderItem(h, item));
      });
      return h(SubMenu, {
        key: menu.fullPath
      }, subItem.concat(itemArr));
    },
    renderItem: function renderItem(h, menu) {
      var meta = menu.meta;

      if (!meta || !meta.invisible) {
        var renderChildren = false;
        var children = menu.children;

        if (children != undefined) {
          for (var i = 0; i < children.length; i++) {
            var childMeta = children[i].meta;

            if (!childMeta || !childMeta.invisible) {
              renderChildren = true;
              break;
            }
          }
        }

        return menu.children && renderChildren ? this.renderSubMenu(h, menu) : this.renderMenuItem(h, menu);
      }
    },
    renderMenu: function renderMenu(h, menuTree) {
      var this_ = this;
      var menuArr = [];
      menuTree.forEach(function (menu, i) {
        menuArr.push(this_.renderItem(h, menu, '0', i));
      });
      return menuArr;
    },
    formatOptions: function formatOptions(options, parentPath) {
      var _this3 = this;

      options.forEach(function (route) {
        var isFullPath = route.path.substring(0, 1) == '/';
        route.fullPath = isFullPath ? route.path : parentPath + '/' + route.path;

        if (route.children) {
          _this3.formatOptions(route.children, route.fullPath);
        }
      });
    },
    updateMenu: function updateMenu() {
      var matchedRoutes = this.$route.matched.filter(function (item) {
        return item.path !== '';
      });
      this.selectedKeys = this.getSelectedKey(this.$route);
      var openKeys = matchedRoutes.map(function (item) {
        return item.path;
      });
      openKeys = openKeys.slice(0, openKeys.length - 1);

      if (!fastEqual(openKeys, this.sOpenKeys)) {
        this.collapsed || this.mode === 'horizontal' ? this.cachedOpenKeys = openKeys : this.sOpenKeys = openKeys;
      }
    },
    getSelectedKey: function getSelectedKey(route) {
      return route.matched.map(function (item) {
        return item.path;
      });
    }
  },
  render: function render(h) {
    var _this4 = this;

    return h(Menu, {
      props: {
        theme: this.menuTheme,
        mode: this.$props.mode,
        selectedKeys: this.selectedKeys,
        openKeys: this.openKeys ? this.openKeys : this.sOpenKeys
      },
      on: {
        'update:openKeys': function updateOpenKeys(val) {
          _this4.sOpenKeys = val;
        },
        click: function click(obj) {
          obj.selectedKeys = [obj.key];

          _this4.$emit('select', obj);
        }
      }
    }, this.renderMenu(h, this.options));
  }
};