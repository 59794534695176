var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    class: ['page-header', _vm.layout, _vm.pageWidth]
  }, [_c('div', {
    staticClass: "page-header-wide"
  }, [_c('div', {
    staticClass: "breadcrumb"
  }, [_c('a-breadcrumb', _vm._l(_vm.breadcrumb, function (item, index) {
    return _c('a-breadcrumb-item', {
      key: index
    }, [_c('span', [_vm._v(_vm._s(item))])]);
  }), 1)], 1), _c('div', {
    staticClass: "detail"
  }, [_c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.showPageTitle && _vm.title ? _c('h1', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c('div', {
    staticClass: "action"
  }, [_vm._t("action")], 2)]), _c('div', {
    staticClass: "row"
  }, [this.$slots.content ? _c('div', {
    staticClass: "content"
  }, [_vm.avatar ? _c('div', {
    staticClass: "avatar"
  }, [_c('a-avatar', {
    attrs: {
      "src": _vm.avatar,
      "size": 72
    }
  })], 1) : _vm._e(), _vm._t("content")], 2) : _vm._e(), this.$slots.extra ? _c('div', {
    staticClass: "extra"
  }, [_vm._t("extra")], 2) : _vm._e()])])])])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };