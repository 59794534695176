import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
import config from '@/config';
import { ADMIN } from '@/config/default';
import { formatFullPath } from '@/utils/i18n';
import { filterMenu } from '@/utils/authority-utils';
import { getLocalSetting } from '@/utils/themeUtil';
var localSetting = getLocalSetting(true);
var customTitlesStr = sessionStorage.getItem(process.env.VUE_APP_TBAS_TITLES_KEY);
var customTitles = customTitlesStr && JSON.parse(customTitlesStr) || [];
export default {
  namespaced: true,
  state: _objectSpread(_objectSpread({
    isMobile: false,
    ieVersion: -1,
    animates: ADMIN.animates,
    palettes: ADMIN.palettes,
    pageMinHeight: 0,
    menuData: [],
    activatedFirst: undefined,
    customTitles: customTitles,
    localConfig: null,
    appVersion: null
  }, config), localSetting),
  getters: {
    appVersion: function appVersion(state) {
      if (!state.appVersion) {
        try {
          var appVersion = localStorage.getItem(process.env.VUE_APP_VERSION_KEY);
          state.appVersion = JSON.parse(appVersion);
        } catch (e) {}
      }

      return state.appVersion;
    },
    menuData: function menuData(state, getters, rootState) {
      if (state.filterMenu) {
        var _rootState$account = rootState.account,
            permissions = _rootState$account.permissions,
            roles = _rootState$account.roles;
        return filterMenu(state.menuData, permissions, roles);
      }

      return state.menuData;
    },
    firstMenu: function firstMenu(state) {
      var menuData = state.menuData;

      if (menuData.length > 0 && !menuData[0].fullPath) {
        formatFullPath(menuData);
      }

      return menuData.map(function (item) {
        var menuItem = _objectSpread({}, item);

        delete menuItem.children;
        return menuItem;
      });
    },
    subMenu: function subMenu(state) {
      var menuData = state.menuData,
          activatedFirst = state.activatedFirst;

      if (menuData.length > 0 && !menuData[0].fullPath) {
        formatFullPath(menuData);
      }

      var current = menuData.find(function (menu) {
        return menu.fullPath === activatedFirst;
      });
      return current && current.children || [];
    },
    localConfig: function localConfig(state) {
      if (!state.localConfig) {
        try {
          var localConfig = localStorage.getItem(process.env.VUE_APP_LOCAL_CONFIG);
          state.localConfig = JSON.parse(localConfig);
          state.localConfig = state.localConfig ? state.localConfig : {
            saveLoginUser: true,
            loginUserName: ''
          };
        } catch (e) {}
      }

      return state.localConfig;
    }
  },
  mutations: {
    setAppVersion: function setAppVersion(state, appVersion) {
      state.appVersion = appVersion;
      localStorage.setItem(process.env.VUE_APP_VERSION_KEY, JSON.stringify(appVersion));
    },
    setDevice: function setDevice(state, isMobile) {
      state.isMobile = isMobile;
    },
    setIeVersion: function setIeVersion(state, ieVersion) {
      state.ieVersion = ieVersion;
    },
    setTheme: function setTheme(state, theme) {
      state.theme = theme;
    },
    setLayout: function setLayout(state, layout) {
      state.layout = layout;
    },
    setMultiPage: function setMultiPage(state, multiPage) {
      state.multiPage = multiPage;
    },
    setAnimate: function setAnimate(state, animate) {
      state.animate = animate;
    },
    setWeekMode: function setWeekMode(state, weekMode) {
      state.weekMode = weekMode;
    },
    setFixedHeader: function setFixedHeader(state, fixedHeader) {
      state.fixedHeader = fixedHeader;
    },
    setFixedSideBar: function setFixedSideBar(state, fixedSideBar) {
      state.fixedSideBar = fixedSideBar;
    },
    setLang: function setLang(state, lang) {
      state.lang = lang;
    },
    setHideSetting: function setHideSetting(state, hideSetting) {
      state.hideSetting = hideSetting;
    },
    correctPageMinHeight: function correctPageMinHeight(state, minHeight) {
      state.pageMinHeight += minHeight;
    },
    setMenuData: function setMenuData(state, menuData) {
      state.menuData = menuData;
    },
    setAsyncRoutes: function setAsyncRoutes(state, asyncRoutes) {
      state.asyncRoutes = asyncRoutes;
    },
    setPageWidth: function setPageWidth(state, pageWidth) {
      state.pageWidth = pageWidth;
    },
    setActivatedFirst: function setActivatedFirst(state, activatedFirst) {
      state.activatedFirst = activatedFirst;
    },
    setFixedTabs: function setFixedTabs(state, fixedTabs) {
      state.fixedTabs = fixedTabs;
    },
    setLocalConfig: function setLocalConfig(state, localConfig) {
      state.localConfig = localConfig;
      localStorage.setItem(process.env.VUE_APP_LOCAL_CONFIG, JSON.stringify(localConfig));
    },
    setCustomTitle: function setCustomTitle(state, _ref) {
      var path = _ref.path,
          title = _ref.title;

      if (title) {
        var obj = state.customTitles.find(function (item) {
          return item.path === path;
        });

        if (obj) {
          obj.title = title;
        } else {
          state.customTitles.push({
            path: path,
            title: title
          });
        }

        sessionStorage.setItem(process.env.VUE_APP_TBAS_TITLES_KEY, JSON.stringify(state.customTitles));
      }
    }
  }
};