import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('admin-layout', [_c('contextmenu', {
    attrs: {
      "itemList": _vm.menuItemList,
      "visible": _vm.menuVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.menuVisible = $event;
      },
      "select": _vm.onMenuSelect
    }
  }), _vm.multiPage ? _c('tabs-head', {
    attrs: {
      "active": _vm.activePage,
      "page-list": _vm.pageList
    },
    on: {
      "change": _vm.changePage,
      "close": _vm.remove,
      "refresh": _vm.refresh,
      "contextmenu": _vm.onContextmenu
    }
  }) : _vm._e(), _c('div', {
    class: ['tabs-view-content', _vm.layout, _vm.pageWidth],
    style: "margin-top: ".concat(_vm.multiPage ? -24 : 0, "px")
  }, [_c('page-toggle-transition', {
    attrs: {
      "disabled": _vm.animate.disabled,
      "animate": _vm.animate.name,
      "direction": _vm.animate.direction
    }
  }, [_vm.multiPage && _vm.cachePage ? _c('a-keep-alive', {
    attrs: {
      "exclude-keys": _vm.excludeKeys
    },
    model: {
      value: _vm.clearCaches,
      callback: function callback($$v) {
        _vm.clearCaches = $$v;
      },
      expression: "clearCaches"
    }
  }, [!_vm.refreshing ? _c('router-view', {
    key: _vm.$route.fullPath,
    ref: "tabContent"
  }) : _vm._e()], 1) : !_vm.refreshing ? _c('router-view', {
    ref: "tabContent"
  }) : _vm._e()], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };