import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import SettingItem from './SettingItem';
import { ColorCheckbox, ImgCheckbox } from '@/components/checkbox';
import Clipboard from 'clipboard';
import { mapState, mapMutations } from 'vuex';
import { formatConfig } from '@/utils/formatter';
import { setting } from '@/config/default';
import sysConfig from '@/config/config';
import fastEqual from 'fast-deep-equal';
import deepMerge from 'deepmerge';
var ColorCheckboxGroup = ColorCheckbox.Group;
var ImgCheckboxGroup = ImgCheckbox.Group;
export default {
  name: 'Setting',
  i18n: require('./i18n'),
  components: {
    ImgCheckboxGroup: ImgCheckboxGroup,
    ImgCheckbox: ImgCheckbox,
    ColorCheckboxGroup: ColorCheckboxGroup,
    ColorCheckbox: ColorCheckbox,
    SettingItem: SettingItem
  },
  data: function data() {
    return {
      copyConfig: 'Sorry, you have copied nothing O(∩_∩)O~',
      isDev: process.env.NODE_ENV === 'development'
    };
  },
  computed: _objectSpread({
    directions: function directions() {
      var _this = this;

      return this.animates.find(function (item) {
        return item.name == _this.animate.name;
      }).directions;
    }
  }, mapState('setting', ['theme', 'layout', 'animate', 'animates', 'palettes', 'multiPage', 'weekMode', 'fixedHeader', 'fixedSideBar', 'hideSetting', 'pageWidth'])),
  watch: {
    'animate.name': function animateName(val) {
      this.setAnimate({
        name: val,
        direction: this.directions[0]
      });
    }
  },
  methods: _objectSpread({
    getPopupContainer: function getPopupContainer() {
      return this.$el.parentNode;
    },
    copyCode: function copyCode() {
      var _this2 = this;

      var config = this.extractConfig(false);
      this.copyConfig = "// \u81EA\u5B9A\u4E49\u914D\u7F6E\uFF0C\u53C2\u8003 ./default/setting.config.js\uFF0C\u9700\u8981\u81EA\u5B9A\u4E49\u7684\u5C5E\u6027\u5728\u8FD9\u91CC\u914D\u7F6E\u5373\u53EF\n      module.exports = ".concat(formatConfig(config), "\n      ");
      var clipboard = new Clipboard('#copyBtn');
      clipboard.on('success', function () {
        _this2.$message.success("\u590D\u5236\u6210\u529F\uFF0C\u8986\u76D6\u6587\u4EF6 src/config/config.js \u7136\u540E\u91CD\u542F\u9879\u76EE\u5373\u53EF\u751F\u6548").then(function () {
          var localConfig = localStorage.getItem(process.env.VUE_APP_SETTING_KEY);

          if (localConfig) {
            _this2.$message.warn('检测到本地有历史保存的主题配置，想要要拷贝的配置代码生效，您可能需要先重置配置', 5);
          }
        });

        clipboard.destroy();
      });
    },
    saveSetting: function saveSetting() {
      var closeMessage = this.$message.loading('正在保存到本地，请稍后...', 0);
      var config = this.extractConfig(true);
      localStorage.setItem(process.env.VUE_APP_SETTING_KEY, JSON.stringify(config));
      setTimeout(closeMessage, 800);
    },
    resetSetting: function resetSetting() {
      this.$confirm({
        title: '重置主题会刷新页面，当前页面内容不会保留，确认重置？',
        onOk: function onOk() {
          localStorage.removeItem(process.env.VUE_APP_SETTING_KEY);
          window.location.reload();
        }
      });
    },
    //提取配置
    extractConfig: function extractConfig() {
      var local = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var config = {};
      var mySetting = this.$store.state.setting;
      var dftSetting = local ? deepMerge(setting, sysConfig) : setting;
      Object.keys(mySetting).forEach(function (key) {
        var dftValue = dftSetting[key],
            myValue = mySetting[key];

        if (dftValue != undefined && !fastEqual(dftValue, myValue)) {
          config[key] = myValue;
        }
      });
      return config;
    }
  }, mapMutations('setting', ['setTheme', 'setLayout', 'setMultiPage', 'setWeekMode', 'setFixedSideBar', 'setFixedHeader', 'setAnimate', 'setHideSetting', 'setPageWidth']))
};