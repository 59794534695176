var ANTD = require('./antd.config');

var ADMIN = require('./admin.config');

var ANIMATE = require('./animate.config');

var setting = require('./setting.config');

module.exports = {
  ANTD: ANTD,
  ADMIN: ADMIN,
  ANIMATE: ANIMATE,
  setting: setting
};