var _objectSpread = require("G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/objectSpread2.js").default;

var _toConsumableArray = require("G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/toConsumableArray.js").default;

require("core-js/modules/es.array.concat.js");

var client = require('webpack-theme-color-replacer/client');

var _require = require('../config'),
    theme = _require.theme;

var _require2 = require('../utils/colors'),
    getMenuColors = _require2.getMenuColors,
    getAntdColors = _require2.getAntdColors,
    getThemeToggleColors = _require2.getThemeToggleColors,
    getFunctionalColors = _require2.getFunctionalColors;

var _require3 = require('../config/default'),
    ANTD = _require3.ANTD;

function getThemeColors(color, $theme) {
  var _color = color || theme.color;

  var mode = $theme || theme.mode;
  var replaceColors = getThemeToggleColors(_color, mode);
  var themeColors = [].concat(_toConsumableArray(replaceColors.mainColors), _toConsumableArray(replaceColors.subColors), _toConsumableArray(replaceColors.menuColors), _toConsumableArray(replaceColors.contentColors), _toConsumableArray(replaceColors.rgbColors), _toConsumableArray(replaceColors.functionalColors.success), _toConsumableArray(replaceColors.functionalColors.warning), _toConsumableArray(replaceColors.functionalColors.error));
  return themeColors;
}

function changeThemeColor(newColor, $theme) {
  var promise = client.changer.changeColor({
    newColors: getThemeColors(newColor, $theme)
  });
  return promise;
}

function modifyVars(color) {
  var _color = color || theme.color;

  var palettes = getAntdColors(_color, theme.mode);
  var menuColors = getMenuColors(_color, theme.mode);

  var _getFunctionalColors = getFunctionalColors(theme.mode),
      success = _getFunctionalColors.success,
      warning = _getFunctionalColors.warning,
      error = _getFunctionalColors.error;

  var primary = palettes[5];
  return _objectSpread({
    'primary-color': primary,
    'primary-1': palettes[0],
    'primary-2': palettes[1],
    'primary-3': palettes[2],
    'primary-4': palettes[3],
    'primary-5': palettes[4],
    'primary-6': palettes[5],
    'primary-7': palettes[6],
    'primary-8': palettes[7],
    'primary-9': palettes[8],
    'primary-10': palettes[9],
    'info-color': primary,
    'success-color': success[5],
    'warning-color': warning[5],
    'error-color': error[5],
    'alert-info-bg-color': palettes[0],
    'alert-info-border-color': palettes[2],
    'alert-success-bg-color': success[0],
    'alert-success-border-color': success[2],
    'alert-warning-bg-color': warning[0],
    'alert-warning-border-color': warning[2],
    'alert-error-bg-color': error[0],
    'alert-error-border-color': error[2],
    'processing-color': primary,
    'menu-dark-submenu-bg': menuColors[0],
    'layout-header-background': menuColors[1],
    'layout-trigger-background': menuColors[2],
    'btn-danger-bg': error[4],
    'btn-danger-border': error[4]
  }, ANTD.theme[theme.mode]);
}

function loadLocalTheme(localSetting) {
  if (localSetting && localSetting.theme) {
    var _localSetting$theme = localSetting.theme,
        color = _localSetting$theme.color,
        mode = _localSetting$theme.mode;
    color = color || theme.color;
    mode = mode || theme.mode;
    changeThemeColor(color, mode);
  }
}
/**
 * 获取本地保存的配置
 * @param load {boolean} 是否加载配置中的主题
 * @returns {Object}
 */


function getLocalSetting(loadTheme) {
  var localSetting = {};

  try {
    var localSettingStr = localStorage.getItem(process.env.VUE_APP_SETTING_KEY);
    localSetting = JSON.parse(localSettingStr);
  } catch (e) {}

  if (loadTheme) {
    loadLocalTheme(localSetting);
  }

  return localSetting;
}

module.exports = {
  getThemeColors: getThemeColors,
  changeThemeColor: changeThemeColor,
  modifyVars: modifyVars,
  loadLocalTheme: loadLocalTheme,
  getLocalSetting: getLocalSetting
};