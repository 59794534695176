import _toConsumableArray from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _typeof from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.link.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import routerMap from '@/router/async/router.map';
import { mergeI18nFromRoutes } from '@/utils/i18n';
import Router from 'vue-router';
import deepMerge from 'deepmerge';
import basicOptions from '@/router/async/config.async'; //应用配置

var appOptions = {
  router: undefined,
  i18n: undefined,
  store: undefined
};
/**
 * 设置应用配置
 * @param options
 */

function setAppOptions(options) {
  var router = options.router,
      store = options.store,
      i18n = options.i18n;
  appOptions.router = router;
  appOptions.store = store;
  appOptions.i18n = i18n;
}
/**
 * 根据 路由配置 和 路由组件注册 解析路由
 * @param routesConfig 路由配置
 * @param routerMap 本地路由组件注册配置
 */


function parseRoutes(routesConfig, routerMap) {
  var routes = [];
  routesConfig.forEach(function (item) {
    var _routeCfg$meta, _router$meta, _routeCfg$meta2, _router$meta2, _routeCfg$meta3, _router$meta3, _routeCfg$meta4, _router$meta4;

    // 获取注册在 routerMap 中的 router，初始化 routeCfg
    var router = undefined,
        routeCfg = {};

    if (typeof item === 'string') {
      router = routerMap[item];
      routeCfg = {
        path: router.path || item,
        router: item
      };
    } else if (_typeof(item) === 'object') {
      router = routerMap[item.router];
      routeCfg = item;
    }

    if (!router) {
      router = typeof item === 'string' ? {
        path: item,
        name: item
      } : item;
    } // 从 router 和 routeCfg 解析路由


    var route = {
      path: routeCfg.path || router.path || routeCfg.router,
      name: routeCfg.name || router.name,
      component: router.component,
      redirect: routeCfg.redirect || router.redirect,
      meta: {
        authority: routeCfg.authority || router.authority || ((_routeCfg$meta = routeCfg.meta) === null || _routeCfg$meta === void 0 ? void 0 : _routeCfg$meta.authority) || ((_router$meta = router.meta) === null || _router$meta === void 0 ? void 0 : _router$meta.authority) || '*',
        icon: routeCfg.icon || router.icon || ((_routeCfg$meta2 = routeCfg.meta) === null || _routeCfg$meta2 === void 0 ? void 0 : _routeCfg$meta2.icon) || ((_router$meta2 = router.meta) === null || _router$meta2 === void 0 ? void 0 : _router$meta2.icon),
        page: routeCfg.page || router.page || ((_routeCfg$meta3 = routeCfg.meta) === null || _routeCfg$meta3 === void 0 ? void 0 : _routeCfg$meta3.page) || ((_router$meta3 = router.meta) === null || _router$meta3 === void 0 ? void 0 : _router$meta3.page),
        link: routeCfg.link || router.link || ((_routeCfg$meta4 = routeCfg.meta) === null || _routeCfg$meta4 === void 0 ? void 0 : _routeCfg$meta4.link) || ((_router$meta4 = router.meta) === null || _router$meta4 === void 0 ? void 0 : _router$meta4.link)
      }
    };

    if (routeCfg.invisible || router.invisible) {
      route.meta.invisible = true;
    }

    if (routeCfg.children && routeCfg.children.length > 0) {
      route.children = parseRoutes(routeCfg.children, routerMap);
    }

    routes.push(route);
  });
  return routes;
}
/**
 * 加载路由
 * @param routesConfig {RouteConfig[]} 路由配置
 */


function loadRoutes(routesConfig) {
  //兼容 0.6.1 以下版本

  /*************** 兼容 version < v0.6.1 *****************/
  if (arguments.length > 0) {
    var arg0 = arguments[0];

    if (arg0.router || arg0.i18n || arg0.store) {
      routesConfig = arguments[1];
    }
  }
  /*************** 兼容 version < v0.6.1 *****************/
  // 应用配置


  var router = appOptions.router,
      store = appOptions.store,
      i18n = appOptions.i18n; // 如果 routesConfig 有值，则更新到本地，否则从本地获取

  if (routesConfig) {
    store.commit('account/setRoutesConfig', routesConfig);
  } else {
    routesConfig = store.getters['account/routesConfig'];
  } // 如果开启了异步路由，则加载异步路由配置


  var asyncRoutes = store.state.setting.asyncRoutes;

  if (asyncRoutes) {
    if (routesConfig && routesConfig.length > 0) {
      var routes = parseRoutes(routesConfig, routerMap);
      var finalRoutes = mergeRoutes(basicOptions.routes, routes);
      formatRoutes(finalRoutes);
      router.options = _objectSpread(_objectSpread({}, router.options), {}, {
        routes: finalRoutes
      });
      router.matcher = new Router(_objectSpread(_objectSpread({}, router.options), {}, {
        routes: []
      })).matcher;
      router.addRoutes(finalRoutes);
    }
  } // 提取路由国际化数据


  mergeI18nFromRoutes(i18n, router.options.routes); // 初始化Admin后台菜单数据

  var rootRoute = router.options.routes.find(function (item) {
    return item.path === '/';
  });
  var menuRoutes = rootRoute && rootRoute.children;

  if (menuRoutes) {
    store.commit('setting/setMenuData', menuRoutes);
  }
}
/**
 * 合并路由
 * @param target {Route[]}
 * @param source {Route[]}
 * @returns {Route[]}
 */


function mergeRoutes(target, source) {
  var routesMap = {};
  target.forEach(function (item) {
    return routesMap[item.path] = item;
  });
  source.forEach(function (item) {
    return routesMap[item.path] = item;
  });
  return Object.values(routesMap);
}
/**
 * 深度合并路由
 * @param target {Route[]}
 * @param source {Route[]}
 * @returns {Route[]}
 */


function deepMergeRoutes(target, source) {
  // 映射路由数组
  var mapRoutes = function mapRoutes(routes) {
    var routesMap = {};
    routes.forEach(function (item) {
      routesMap[item.path] = _objectSpread(_objectSpread({}, item), {}, {
        children: item.children ? mapRoutes(item.children) : undefined
      });
    });
    return routesMap;
  };

  var tarMap = mapRoutes(target);
  var srcMap = mapRoutes(source); // 合并路由

  var merge = deepMerge(tarMap, srcMap); // 转换为 routes 数组

  var parseRoutesMap = function parseRoutesMap(routesMap) {
    return Object.values(routesMap).map(function (item) {
      if (item.children) {
        item.children = parseRoutesMap(item.children);
      } else {
        delete item.children;
      }

      return item;
    });
  };

  return parseRoutesMap(merge);
}
/**
 * 格式化路由
 * @param routes 路由配置
 */


function formatRoutes(routes) {
  routes.forEach(function (route) {
    var path = route.path;

    if (!path.startsWith('/') && path !== '*') {
      route.path = '/' + path;
    }
  });
  formatAuthority(routes);
}
/**
 * 格式化路由的权限配置
 * @param routes 路由
 * @param pAuthorities 父级路由权限配置集合
 */


function formatAuthority(routes) {
  var pAuthorities = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  routes.forEach(function (route) {
    var meta = route.meta;
    var defaultAuthority = pAuthorities[pAuthorities.length - 1] || {
      permission: '*'
    };

    if (meta) {
      var authority = {};

      if (!meta.authority) {
        authority = defaultAuthority;
      } else if (typeof meta.authority === 'string') {
        authority.permission = meta.authority;
      } else if (_typeof(meta.authority) === 'object') {
        authority = meta.authority;
        var _authority = authority,
            role = _authority.role;

        if (typeof role === 'string') {
          authority.role = [role];
        }

        if (!authority.permission && !authority.role) {
          authority = defaultAuthority;
        }
      }

      meta.authority = authority;
    } else {
      var _authority2 = defaultAuthority;
      route.meta = {
        authority: _authority2
      };
    }

    route.meta.pAuthorities = pAuthorities;

    if (route.children) {
      formatAuthority(route.children, [].concat(_toConsumableArray(pAuthorities), [route.meta.authority]));
    }
  });
}
/**
 * 从路由 path 解析 i18n key
 * @param path
 * @returns {*}
 */


function getI18nKey(path) {
  var keys = path.split('/').filter(function (item) {
    return !item.startsWith(':') && item != '';
  });
  keys.push('name');
  return keys.join('.');
}
/**
 * 加载导航守卫
 * @param guards
 * @param options
 */


function loadGuards(guards, options) {
  var beforeEach = guards.beforeEach,
      afterEach = guards.afterEach;
  var router = options.router;
  beforeEach.forEach(function (guard) {
    if (guard && typeof guard === 'function') {
      router.beforeEach(function (to, from, next) {
        return guard(to, from, next, options);
      });
    }
  });
  afterEach.forEach(function (guard) {
    if (guard && typeof guard === 'function') {
      router.afterEach(function (to, from) {
        return guard(to, from, options);
      });
    }
  });
}

export { parseRoutes, loadRoutes, formatAuthority, getI18nKey, loadGuards, deepMergeRoutes, formatRoutes, setAppOptions };