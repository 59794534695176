//跨域代理前缀
var API_PROXY_PREFIX = '/api';
var BASE_URL = process.env.VUE_APP_MOCK === '1' ? '/mock_api' : API_PROXY_PREFIX; // const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_BASE_URL : API_PROXY_PREFIX
// const BASE_URL = process.env.VUE_APP_API_BASE_URL

module.exports = {
  // 登录与权限
  LOGIN: "".concat(BASE_URL, "/account/login"),
  LOGOUT: "".concat(BASE_URL, "/user/exit"),
  UPDATEPASSWORD: "".concat(BASE_URL, "/user/updatePassword"),
  ROUTES: "".concat(BASE_URL, "/resource/authorityMenu"),
  TOKENKEY: "".concat(BASE_URL, "/account/login?tokenKey=get"),
  DEVICESTATUSDATA: "".concat(BASE_URL, "/device/deviceStatusData"),
  // 获取设备时间范围内的处理量
  DEVICEINOUTDATA: "".concat(BASE_URL, "/device/deviceInOutData"),
  DEVICEINFODAYLIST: "".concat(BASE_URL, "/device/deviceInfoDayList"),
  DEVICEDAYDATA: "".concat(BASE_URL, "/device/deviceDayData"),
  // 获取设备区域分佈数量
  DEVICEREGION: "".concat(BASE_URL, "/device/deviceRegion"),
  // 获取设备故障信息
  DEVICEERRORLIST: "".concat(BASE_URL, "/error/deviceErrorList"),
  // 获取设备列表
  DEVICELIST: "".concat(BASE_URL, "/device/deviceList"),
  // 获取设备运行信息
  GETDEVICEINFOS: "".concat(BASE_URL, "/device/getDeviceInfos"),
  // 设置设备授权状态
  SETPERMISSION: "".concat(BASE_URL, "/device/setPermission"),
  // 获取时间段内设备运行信息筛选点
  GETDEVICEINFOSBYDATE: "".concat(BASE_URL, "/device/getDeviceInfosByDate"),
  DEVICE: "".concat(BASE_URL, "/device"),
  DEVICEERRRANK: "".concat(BASE_URL, "/error/deviceErrRank"),
  ERROREXPORT: "".concat(BASE_URL, "/error/export"),
  DEVICEMODELLIST: "".concat(BASE_URL, "/device/deviceModelList"),
  DEVICEMODEL: "".concat(BASE_URL, "/device/deviceModel"),
  CUSTOMER: "".concat(BASE_URL, "/customer"),
  PRODUCESCALE: "".concat(BASE_URL, "/customer/produceScale"),
  GETCUSTOMERLIST: "".concat(BASE_URL, "/customer/getCustomerList"),
  ADDCUSTOMER: "".concat(BASE_URL, "/customer/addCustomer"),
  RESETPASSWORD: "".concat(BASE_URL, "/customer/resetPassword"),
  GETCUSTOMERDETAIL: "".concat(BASE_URL, "/customer/getCustomerDetail"),
  UPDATECUSTOMER: "".concat(BASE_URL, "/customer/updateCustomer"),
  ADDLIAISON: "".concat(BASE_URL, "/customer/addLiaison"),
  UPDATELIAISON: "".concat(BASE_URL, "/customer/updateLiaison"),
  //获取父节点下属region信息
  REGIONLIST: "".concat(BASE_URL, "/regionList"),
  // 文件服务
  SAVEMONFILE: "".concat(BASE_URL, "/monfile/saveMonFile"),
  DELETEMONFILE: "".concat(BASE_URL, "/monfile/deleteMonFile"),
  GETMONFILE: "".concat(BASE_URL, "/monfile/getMonFile"),
  OSSSAVEMONFILE: "".concat(BASE_URL, "/ossfile/saveFile"),
  AUTHORITYMENU: "".concat(BASE_URL, "/resource/authorityMenu"),
  // 设备错误码配置接口
  GETERRCODELIST: "".concat(BASE_URL, "/errCode/GetErrCodeList"),
  DELETEERRCODE: "".concat(BASE_URL, "/errCode/deleteErrCode"),
  INSSERTERRCODE: "".concat(BASE_URL, "/errCode/inssertErrCode"),
  UPDATEERRCODE: "".concat(BASE_URL, "/errCode/updateErrCode"),
  EXPORTERRCODEEXCEL: "".concat(BASE_URL, "/errCode/exportErrCodeExcel"),
  ADDEXCELERRCODE: "".concat(BASE_URL, "/errCode/addExcelErrCode"),
  //监控接口
  ADDVIDEO: "".concat(BASE_URL, "/video/addVideo"),
  ADDDEVICE2YS: "".concat(BASE_URL, "/video/addDevice2ys"),
  CONTROL: "".concat(BASE_URL, "/video/control"),
  ENCRYPT: "".concat(BASE_URL, "/video/encrypt"),
  GETINDEXVIDEO: "".concat(BASE_URL, "/video/getIndexVideo"),
  GETVIDEOINFO: "".concat(BASE_URL, "/video/getVideoInfo"),
  GETVIDEOLIST: "".concat(BASE_URL, "/video/getVideoList"),
  UPDATEVIDEO: "".concat(BASE_URL, "/video/updateVideo"),
  DELETEVIDEO: "".concat(BASE_URL, "/video/deleteVideo"),
  GETACCESSTOKEN: "".concat(BASE_URL, "/video/getAccessToken"),
  GETBINDVIDEOLIST: "".concat(BASE_URL, "/device/getBindVideoList"),
  GETTOTAL: "".concat(BASE_URL, "/device/getTotal")
};