<template>
  <a-modal
    :visible="visible"
    :class="width>500?'w_l':'w_s'"
    class="class_modal_no_mb"
    :okText="oktext"
    :width="width"
    :centered="centered"
    :footer="footer"
    :maskClosable="maskClosable"
    :destroyOnClose="destroyOnClose"
    @cancel="
      () => {
        $emit('cancel');
      }
    "
    @ok="
      () => {
        $emit('ok');
      }
    "
  >
    <a-spin :spinning="spinning">
      <slot></slot>
    </a-spin>
    <div v-if="title!==null" slot="title" class="class_modal_no_mb_title">
      <span class="class_modal_no_mb_title_v">{{ title }}</span>
    </div>
    <div slot="footer" class="class_modal_no_mb_footer">
      <a-space size="large">
        <a-button class="class_modal_no_mb_footer_btn" key="submit" type="primary" :loading="loading" @click="handleOk">
          {{ oktext }}
        </a-button>
        <a-button class="class_modal_no_mb_footer_btn" key="cancel" type="primary" @click="handleCancel">
          {{ cancelText }}
        </a-button>
      </a-space>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "PopupForm",
  props: {
    title: {
      type: String,
      default: "unnamed",
    },
    centered: {
      type: Boolean,
      default: false,
    },
    oktext: {
      type: String,
      default: "提交",
    },
    cancelText: {
      type: String,
      default: "取消",
    },
    width: {
      type: Number,
      default: 400,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    maskClosable: {
      type: Boolean,
      default: true,
    },
    footer: {
      type: String,
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    spinning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  beforeCreate() {},
  methods:{
    handleOk() {
      this.$emit('ok');
    },
    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="less" scoped>
.w_s {
  :deep(.ant-modal-content) {
    border: 3px solid #fff0;
    border-image:url(../../assets/img/border_p.png) 54 125 42 120 fill round;
    border-image-width: 54px 125px 42px 120px;
  }
}
.w_l {
  :deep(.ant-modal-content) {
    border: 3px solid #fff0;
    border-image:url(../../assets/img/border_pl.png) 54 125 42 120 fill stretch;
    border-image-width: 54px 125px 42px 120px;
  }
}
.class_modal_no_mb {
  :deep(.ant-modal-content) {
    background-color: #0000;
    box-shadow: none;
    color: #fff;
    .ant-modal-header {
      background: none;
      border-bottom: none;
      padding-bottom: 0;
    }
    .ant-modal-close {
      top: -4px;
      right: -3.5px;
      border-radius: 50%;
    }
    .ant-modal-close-x {
      background: #009cff;
      border-radius: 50%;
      width: 36px;
      height: 36px;
      line-height: 36px;
    }
    .ant-modal-close-x:hover {
      background: #009cff;
      filter: brightness(90%)
    }
    .ant-modal-body {
      padding-top: 0;
    }
    .ant-modal-footer {
      border-top: none;
    }
    .ant-input {
      background-color: #02174e;
      color: #fff;
      border-color: #072866;
    }
    .ant-input:hover {
      border-color: #40a9ff;
    }
    .ant-input:active {
      border-color: #40a9ff;
    }
    .ant-input:focus {
      border-color: #40a9ff;
    }
    .ant-cascader-picker {
      background-color: #02174e;
      color: #fff;
    }
    .ant-calendar-picker-clear {
      background-color: #02174e;
      color: #666;
    }
    .ant-calendar-picker-clear:hover {
      background-color: #02174e;
      color: #fff;
    }
    .ant-cascader-picker-clear {
      background-color: #0000;
      color: #fff6;
    }
    .ant-select-selection {
      background-color: #02174e;
      color: #fff;
      border-color: #072866;
    }
    .ant-select-selection:hover {
      border-color: #40a9ff;
    }
    .ant-select-selection:focus {
      border-color: #40a9ff;
    }
    .ant-select-selection:active {
      border-color: #40a9ff;
    }
    .ant-form-item-label {
      > label {
        color: #ffffff;
      }
    }
    .ant-card {
      background-color: #fff0;
      .ant-card-head {
        color: #fff;
      }
    }
    // .ant-card-bordered {
    //   border: none;
    // }
    .ant-divider-inner-text {
      color: #fff;
    }
    .ant-divider-horizontal.ant-divider-with-text-left::after {
      top: 0;
      border-top: 1px dashed #fff5
    }
    .ant-divider-horizontal.ant-divider-with-text-left::before {
      top: 0;
      width: 1%;
      border-top-style: none;
    }
    .ant-table-thead {
      > tr {
        > th {
          color: #00f8fe;
          background-color: #021f59;
          border-bottom-color: #fff4;
          text-align: center;
        }
      }
    }
    .ant-table-tbody {
      > tr {
        > td {
          color: #fff;
          border-bottom-color: #fff4;
          text-align: center;
        }
      }
      > tr:hover {
        > td {
          background-color: #fff2;
        }
      }

    }
    .addbtn {
      background-color:#fff2;
      color: #fff;
    }
    .addbtn:hover {
      color: #40a9ff;
    }
    .ant-upload-select-picture-card {
      background-color: #fff1;
    }
    .ant-empty-normal {
      // min-height: 190px;
      background-color: #fff0;
      color: #00f8fe;
    }
    .ant-table-placeholder {
      background-color: #fff0;
      border-top-color: #fff4;
      border-bottom-color: #fff4;
    }
    .ant-modal-close {
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
    }
    .ant-modal-close:focus, .ant-modal-close:hover {
      color: #fff;
      text-decoration: none;
      // transform: scale(1.2);
    }
  } 
  &_form {
    width: 100%;
  }
  &_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    line-height: 50px;
    &_v {
      font-size: 32px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #00ECFF;
    }
  }
  &_footer{
    width: 100%;
    text-align: center;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    &_btn {
      width: 160px;
      height: 50px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-bottom: 20px;
      background: linear-gradient(90deg, #008dff 0%, #41dbfa 100%);
    }
  }
}
</style>

<style lang="less">
// .class_modal_no_mb {
//   .ant-form-item {
//     margin-bottom: 0;
//   }
// }
</style>
