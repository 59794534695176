var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-layout', {
    class: ['admin-layout', 'beauty-scroll']
  }, [!_vm.hideSetting1 ? _c('drawer', {
    attrs: {
      "placement": "right"
    },
    model: {
      value: _vm.showSetting,
      callback: function callback($$v) {
        _vm.showSetting = $$v;
      },
      expression: "showSetting"
    }
  }, [_c('div', {
    staticClass: "setting",
    attrs: {
      "slot": "handler"
    },
    slot: "handler"
  }, [_c('a-icon', {
    attrs: {
      "type": _vm.showSetting ? 'close' : 'setting'
    }
  })], 1), _c('setting')], 1) : _vm._e(), _c('change-psw', {
    attrs: {
      "visible": _vm.showPassDialog
    },
    on: {
      "cancel": _vm.hidePassDialog,
      "create": _vm.hidePassDialog
    }
  }), _c('a-layout', {
    staticClass: "admin-layout-main beauty-scroll almhdbackgroundImage"
  }, [_c('admin-header', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideHeader,
      expression: "!hideHeader"
    }],
    class: [{
      'fixed-tabs': _vm.fixedTabs,
      'fixed-header': _vm.fixedHeader,
      'multi-page': _vm.multiPage
    }],
    style: _vm.headerStyle,
    attrs: {
      "menuData": _vm.headMenuData,
      "collapsed": _vm.collapsed
    },
    on: {
      "toggleCollapse": _vm.toggleCollapse,
      "logout": _vm.tologout,
      "changePSW": _vm.changePSW
    }
  }), _c('a-layout-header', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fixedHeader,
      expression: "fixedHeader"
    }],
    class: ['virtual-header', {
      'fixed-tabs': _vm.fixedTabs,
      'fixed-header': _vm.fixedHeader,
      'multi-page': _vm.multiPage
    }]
  }), _c('a-layout-content', {
    staticClass: "admin-layout-content",
    style: "min-height: ".concat(_vm.minHeight, "px;")
  }, [_c('div', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._t("default")], 2)]), _c('a-layout-footer', {
    staticStyle: {
      "padding": "0px"
    }
  }, [_c('page-footer', {
    attrs: {
      "link-list": _vm.footerLinks,
      "copyright": _vm.copyright
    }
  })], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };