import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapGetters } from 'vuex';
import { logout as _logout } from '@/services/user';
export default {
  name: 'HeaderAvatar',
  computed: _objectSpread({}, mapGetters('account', ['user'])),
  methods: {
    logout: function logout() {
      _logout();

      this.$router.push('/login');
    }
  }
};