var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-tooltip', {
    attrs: {
      "title": _vm.title,
      "overlayStyle": {
        zIndex: 2001
      }
    }
  }, [_c('div', {
    staticClass: "img-check-box",
    on: {
      "click": _vm.toggle
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.img
    }
  }), _vm.sChecked ? _c('div', {
    staticClass: "check-item"
  }, [_c('a-icon', {
    attrs: {
      "type": "check"
    }
  })], 1) : _vm._e()])]);
};

var staticRenderFns = [];
export { render, staticRenderFns };