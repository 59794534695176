import _createForOfIteratorHelper from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _typeof from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";

/**
 * 判断是否有路由的权限
 * @param authority 路由权限配置
 * @param permissions 用户权限集合
 * @returns {boolean|*}
 */
function hasPermission(authority, permissions) {
  var required = '*';

  if (typeof authority === 'string') {
    required = authority;
  } else if (_typeof(authority) === 'object') {
    required = authority.permission;
  }

  return required === '*' || permissions && permissions.findIndex(function (item) {
    return item === required || item.id === required;
  }) !== -1;
}
/**
 * 判断是否有路由需要的角色
 * @param authority 路由权限配置
 * @param roles 用户角色集合
 */


function hasRole(authority, roles) {
  var required = undefined;

  if (_typeof(authority) === 'object') {
    required = authority.role;
  }

  return authority === '*' || hasAnyRole(required, roles);
}
/**
 * 判断是否有需要的任意一个角色
 * @param required {String | Array[String]} 需要的角色，可以是单个角色或者一个角色数组
 * @param roles 拥有的角色
 * @returns {boolean}
 */


function hasAnyRole(required, roles) {
  if (!required) {
    return false;
  } else if (Array.isArray(required)) {
    return roles.findIndex(function (role) {
      return required.findIndex(function (item) {
        return item === role || item === role.id;
      }) !== -1;
    }) !== -1;
  } else {
    return roles.findIndex(function (role) {
      return role === required || role.id === required;
    }) !== -1;
  }
}
/**
 * 路由权限校验
 * @param route 路由
 * @param permissions 用户权限集合
 * @param roles 用户角色集合
 * @returns {boolean}
 */


function hasAuthority(route, permissions, roles) {
  var authorities = [].concat(_toConsumableArray(route.meta.pAuthorities), [route.meta.authority]);

  var _iterator = _createForOfIteratorHelper(authorities),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var authority = _step.value;

      if (!hasPermission(authority, permissions) && !hasRole(authority, roles)) {
        return false;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return true;
}
/**
 * 根据权限配置过滤菜单数据
 * @param menuData
 * @param permissions
 * @param roles
 */


function filterMenu(menuData, permissions, roles) {
  return menuData.filter(function (menu) {
    if (menu.meta && menu.meta.invisible === undefined) {
      if (!hasAuthority(menu, permissions, roles)) {
        return false;
      }
    }

    if (menu.children && menu.children.length > 0) {
      menu.children = filterMenu(menu.children, permissions, roles);
    }

    return true;
  });
}

export { filterMenu, hasAuthority };