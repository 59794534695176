var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "setting-item"
  }, [_vm.title ? _c('h3', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._t("default")], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };