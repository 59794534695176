import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { mapState } from 'vuex';
export default {
  name: 'PageHeader',
  props: {
    title: {
      type: [String, Boolean],
      required: false
    },
    breadcrumb: {
      type: Array,
      required: false
    },
    logo: {
      type: String,
      required: false
    },
    avatar: {
      type: String,
      required: false
    }
  },
  computed: _objectSpread({}, mapState('setting', ['layout', 'showPageTitle', 'pageWidth']))
};