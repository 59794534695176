import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
// 视图组件
var view = {
  tabs: function tabs() {
    return import('@/layouts/tabs');
  },
  blank: function blank() {
    return import('@/layouts/BlankView');
  },
  page: function page() {
    return import('@/layouts/PageView');
  }
}; // 路由组件注册

var routerMap = {
  login: {
    authority: '*',
    path: '/login',
    name: '登录',
    component: function component() {
      return import('@/pages/login');
    }
  },
  checklogin: {
    authority: '*',
    path: '/checklogin',
    component: function component() {
      return import('@/pages/login/CheckLogin.vue');
    }
  },
  root: {
    path: '/',
    // name: '首页',
    redirect: '/checklogin',
    // component: view.blank,
    component: view.tabs
  },
  dashboard: {
    // path: '/dashboard',
    // name: '首页',
    // redirect: '/dashboard',
    component: view.blank // component: view.tabs,

  },
  adminboard: {
    path: '/dashboard',
    name: '首页',
    invisible: true,
    meta: {
      icon: 'dashboard',
      page: {
        cacheAble: true
      }
    },
    // component: view.blank
    component: function component() {
      return import('@/pages/dashboard/dashboard');
    }
  },
  userboard: {
    name: '首页',
    path: '/dashboard',
    invisible: true,
    meta: {
      icon: 'dashboard',
      page: {
        cacheAble: true
      }
    },
    component: function component() {
      return import('@/pages/dashboard/userboard');
    }
  },
  workplace: {
    name: '工作台',
    component: function component() {
      return import('@/pages/dashboard/dashboard');
    }
  },
  analysis: {
    name: '分析页',
    component: function component() {
      return import('@/pages/dashboard/userboard');
    }
  },
  monitor: {
    path: '/monitor',
    name: '监控列表',
    meta: {
      icon: '',
      page: {
        cacheAble: true,
        breadcrumb: ['首页', '设备管理', '监控列表']
      }
    },
    component: function component() {
      return import('@/pages/monitor');
    }
  },
  system: {
    patch: 'system',
    name: '系统设置',
    redirect: '/system/user',
    meta: {
      icon: '',
      page: {
        cacheAble: true,
        breadcrumb: ['首页', '系统设置']
      }
    },
    component: view.blank
  },
  user: {
    path: '/system/user',
    name: '用户管理',
    meta: {
      icon: '',
      page: {
        cacheAble: true,
        breadcrumb: ['首页', '系统设置', '用户管理']
      }
    },
    component: function component() {
      return import('@/pages/system/user');
    }
  },
  model: {
    path: '/system/model',
    name: '型号管理',
    meta: {
      icon: '',
      page: {
        cacheAble: true,
        breadcrumb: ['首页', '系统设置', '型号管理']
      }
    },
    component: function component() {
      return import('@/pages/system/model');
    }
  },
  devices: {
    // path: 'devices',
    name: '设备管理',
    meta: {
      icon: '',
      page: {
        cacheAble: false
      }
    },
    component: view.blank
  },
  deviceslist: {
    path: '/devices',
    name: '设备列表',
    invisible: true,
    meta: {
      invisible: 'true',
      page: {
        cacheAble: false,
        breadcrumb: ['首页', '设备管理', '设备列表']
      }
    },
    component: function component() {
      return import('@/pages/device');
    }
  },
  devicesdetail: {
    path: '/devices/detail',
    name: '查看设备',
    // renderMenu: false,
    invisible: true,
    meta: {
      invisible: 'true',
      page: {
        cacheAble: false,
        breadcrumb: ['首页', '设备管理', '查看设备']
      }
    },
    component: function component() {
      return import('@/pages/device/detail');
    }
  },
  // form: {
  //   name: '表单页',
  //   icon: 'form',
  //   component: view.page
  // },
  // basicForm: {
  //   path: 'basic',
  //   name: '基础表单',
  //   component: () => import('@/pages/form/basic')
  // },
  // stepForm: {
  //   path: 'step',
  //   name: '分步表单',
  //   component: () => import('@/pages/form/step')
  // },
  // advanceForm: {
  //   path: 'advance',
  //   name: '高级表单',
  //   component: () => import('@/pages/form/advance')
  // },
  // list: {
  //   name: '列表页',
  //   icon: 'table',
  //   component: view.page
  // },
  // queryList: {
  //   path: 'query',
  //   name: '查询表格',
  //   component: () => import('@/pages/list/QueryList')
  // },
  // primaryList: {
  //   path: 'primary',
  //   name: '标准列表',
  //   component: () => import('@/pages/list/StandardList')
  // },
  // cardList: {
  //   path: 'card',
  //   name: '卡片列表',
  //   component: () => import('@/pages/list/CardList')
  // },
  // searchList: {
  //   path: 'search',
  //   name: '搜索列表',
  //   component: () => import('@/pages/list/search/SearchLayout')
  // },
  // article: {
  //   name: '文章',
  //   component: () => import('@/pages/list/search/ArticleList')
  // },
  // application: {
  //   name: '应用',
  //   component: () => import('@/pages/list/search/ApplicationList')
  // },
  // project: {
  //   name: '项目',
  //   component: () => import('@/pages/list/search/ProjectList')
  // },
  // details: {
  //   name: '详情页',
  //   icon: 'profile',
  //   component: view.blank
  // },
  // basicDetails: {
  //   path: 'basic',
  //   name: '基础详情页',
  //   component: () => import('@/pages/detail/BasicDetail')
  // },
  // advanceDetails: {
  //   path: 'advance',
  //   name: '高级详情页',
  //   component: () => import('@/pages/detail/AdvancedDetail')
  // },
  // result: {
  //   name: '结果页',
  //   icon: 'check-circle-o',
  //   component: view.page
  // },
  // success: {
  //   name: '成功',
  //   component: () => import('@/pages/result/Success')
  // },
  // error: {
  //   name: '失败',
  //   component: () => import('@/pages/result/Error')
  // },
  // exception: {
  //   name: '异常页',
  //   icon: 'warning',
  //   component: view.blank
  // },
  exp403: {
    authority: '*',
    name: 'exp403',
    path: '403',
    component: function component() {
      return import('@/pages/exception/403');
    }
  },
  exp404: {
    name: 'exp404',
    path: '404',
    component: function component() {
      return import('@/pages/exception/404');
    }
  } // exp500: {
  //   name: 'exp500',
  //   path: '500',
  //   component: () => import('@/pages/exception/500')
  // },
  // components: {
  //   name: '小组件',
  //   icon: 'appstore-o',
  //   component: view.page
  // },
  // taskCard: {
  //   name: '任务卡片',
  //   component: () => import('@/pages/components/TaskCard')
  // },
  // palette: {
  //   name: '颜色复选框',
  //   component: () => import('@/pages/components/Palette')
  // }

};
export default routerMap;