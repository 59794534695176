var _toConsumableArray = require("G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/toConsumableArray.js").default;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.set.js");

require("core-js/modules/es.string.iterator.js");

require("core-js/modules/web.dom-collections.iterator.js");

require("core-js/modules/es.array.slice.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.split.js");

var varyColor = require('webpack-theme-color-replacer/client/varyColor');

var _require = require('@ant-design/colors'),
    generate = _require.generate;

var _require2 = require('../config/default'),
    ADMIN = _require2.ADMIN,
    ANTD = _require2.ANTD;

var Config = require('../config');

var themeMode = ADMIN.theme.mode; // 获取 ant design 色系

function getAntdColors(color, mode) {
  var options = mode && mode == themeMode.NIGHT ? {
    theme: 'dark'
  } : undefined;
  return generate(color, options);
} // 获取功能性颜色


function getFunctionalColors(mode) {
  var options = mode && mode == themeMode.NIGHT ? {
    theme: 'dark'
  } : undefined;
  var _ANTD$primary = ANTD.primary,
      success = _ANTD$primary.success,
      warning = _ANTD$primary.warning,
      error = _ANTD$primary.error;
  var _Config$theme = Config.theme,
      s1 = _Config$theme.success,
      w1 = _Config$theme.warning,
      e1 = _Config$theme.error;
  success = success && s1;
  warning = success && w1;
  error = success && e1;
  var successColors = generate(success, options);
  var warningColors = generate(warning, options);
  var errorColors = generate(error, options);
  return {
    success: successColors,
    warning: warningColors,
    error: errorColors
  };
} // 获取菜单色系


function getMenuColors(color, mode) {
  if (mode == themeMode.NIGHT) {
    return ANTD.primary.night.menuColors;
  } else if (color == ANTD.primary.color) {
    return ANTD.primary.dark.menuColors;
  } else {
    return [varyColor.darken(color, 0.93), varyColor.darken(color, 0.83), varyColor.darken(color, 0.73)];
  }
} // 获取主题模式切换色系


function getThemeToggleColors(color, mode) {
  //主色系
  var mainColors = getAntdColors(color, mode);
  var primary = mainColors[5]; //辅助色系，因为 antd 目前没针对夜间模式设计，所以增加辅助色系以保证夜间模式的正常切换

  var subColors = getAntdColors(primary, themeMode.LIGHT); //菜单色系

  var menuColors = getMenuColors(color, mode); //内容色系（包含背景色、文字颜色等）

  var themeCfg = ANTD.theme[mode];
  var contentColors = Object.keys(themeCfg).map(function (key) {
    return themeCfg[key];
  }).map(function (color) {
    return isHex(color) ? color : toNum3(color).join(',');
  }); // 内容色去重

  contentColors = _toConsumableArray(new Set(contentColors)); // rgb 格式的主题色

  var rgbColors = [toNum3(primary).join(',')];
  var functionalColors = getFunctionalColors(mode);
  return {
    primary: primary,
    mainColors: mainColors,
    subColors: subColors,
    menuColors: menuColors,
    contentColors: contentColors,
    rgbColors: rgbColors,
    functionalColors: functionalColors
  };
}

function toNum3(color) {
  if (isHex(color)) {
    return varyColor.toNum3(color);
  }

  var colorStr = '';

  if (isRgb(color)) {
    colorStr = color.slice(5, color.length);
  } else if (isRgba(color)) {
    colorStr = color.slice(6, color.lastIndexOf(','));
  }

  var rgb = colorStr.split(',');
  var r = parseInt(rgb[0]);
  var g = parseInt(rgb[1]);
  var b = parseInt(rgb[2]);
  return [r, g, b];
}

function isHex(color) {
  return color.length >= 4 && color[0] == '#';
}

function isRgb(color) {
  return color.length >= 10 && color.slice(0, 3) == 'rgb';
}

function isRgba(color) {
  return color.length >= 13 && color.slice(0, 4) == 'rgba';
}

module.exports = {
  isHex: isHex,
  isRgb: isRgb,
  isRgba: isRgba,
  toNum3: toNum3,
  getAntdColors: getAntdColors,
  getMenuColors: getMenuColors,
  getThemeToggleColors: getThemeToggleColors,
  getFunctionalColors: getFunctionalColors
};