import _createForOfIteratorHelper from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.find-index.js";
import AdminHeader from './header/AdminHeader';
import PageFooter from './footer/PageFooter';
import Drawer from '../components/tool/Drawer';
import SideMenu from '../components/menu/SideMenu';
import Setting from '../components/setting/Setting';
import ChangePsw from '../pages/components/ChangePSW.vue';
import { mapState, mapMutations, mapGetters } from 'vuex'; // eslint-disable-next-line no-unused-vars

import { logout } from '@/services/user'; // const minHeight = window.innerHeight - 64 - 122

export default {
  name: 'AdminLayout',
  // eslint-disable-next-line vue/no-unused-components
  components: {
    Setting: Setting,
    SideMenu: SideMenu,
    Drawer: Drawer,
    PageFooter: PageFooter,
    AdminHeader: AdminHeader,
    ChangePsw: ChangePsw
  },
  data: function data() {
    return {
      // minHeight: window.innerHeight - 64 - 122,
      // minHeight: window.innerHeight - 92 - 45,
      minHeight: 943,
      collapsed: false,
      hideHeader: false,
      showSetting: false,
      drawerOpen: false,
      hideSetting1: true,
      showPassDialog: false
    };
  },
  provide: function provide() {
    return {
      adminLayout: this
    };
  },
  watch: {
    $route: function $route(val) {
      this.setActivated(val);
    },
    layout: function layout() {
      this.setActivated(this.$route);
    },
    isMobile: function isMobile(val) {
      if (!val) {
        this.drawerOpen = false;
      }
    }
  },
  computed: _objectSpread(_objectSpread(_objectSpread({}, mapState('setting', ['isMobile', 'theme', 'layout', 'footerLinks', 'copyright', 'fixedHeader', 'fixedSideBar', 'fixedTabs', 'hideSetting', 'multiPage'])), mapGetters('setting', ['firstMenu', 'subMenu', 'menuData'])), {}, {
    sideMenuWidth: function sideMenuWidth() {
      return this.collapsed ? '80px' : '256px';
    },
    headerStyle: function headerStyle() {
      var width = this.fixedHeader && this.layout !== 'head' && !this.isMobile ? "calc(100% - ".concat(this.sideMenuWidth, ")") : '100%';
      var position = this.fixedHeader ? 'fixed' : 'static';
      return "width: ".concat(width, "; position: ").concat(position, ";");
    },
    headMenuData: function headMenuData() {
      var layout = this.layout,
          menuData = this.menuData,
          firstMenu = this.firstMenu;
      return layout === 'mix' ? firstMenu : menuData;
    },
    sideMenuData: function sideMenuData() {
      var layout = this.layout,
          menuData = this.menuData,
          subMenu = this.subMenu;
      return layout === 'mix' ? subMenu : menuData;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('setting', ['correctPageMinHeight', 'setActivatedFirst'])), {}, {
    toggleCollapse: function toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    onMenuSelect: function onMenuSelect() {
      this.toggleCollapse();
    },
    setActivated: function setActivated(route) {
      var _this = this;

      if (this.layout === 'mix') {
        var matched = route.matched;
        matched = matched.slice(0, matched.length - 1);
        var firstMenu = this.firstMenu;

        var _iterator = _createForOfIteratorHelper(firstMenu),
            _step;

        try {
          var _loop = function _loop() {
            var menu = _step.value;

            if (matched.findIndex(function (item) {
              return item.path === menu.fullPath;
            }) !== -1) {
              _this.setActivatedFirst(menu.fullPath);

              return "break";
            }
          };

          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _ret = _loop();

            if (_ret === "break") break;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    },
    tologout: function tologout() {
      logout();
      this.$router.push('/login');
    },
    change: function change() {
      logout();
      this.$router.push('/login');
    },
    changePSW: function changePSW() {
      this.showPassDialog = true;
    },
    hidePassDialog: function hidePassDialog() {
      this.showPassDialog = false;
    }
  }),
  created: function created() {
    this.correctPageMinHeight(this.minHeight - 24);
    this.setActivated(this.$route);
  },
  beforeDestroy: function beforeDestroy() {
    this.correctPageMinHeight(-this.minHeight + 24);
  }
};