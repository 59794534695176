var _typeof = require("G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/typeof.js").default;

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.object.keys.js");

/**
 * 把对象按照 js配置文件的格式进行格式化
 * @param obj 格式化的对象
 * @param dep 层级，此项无需传值
 * @returns {string}
 */
function formatConfig(obj, dep) {
  dep = dep || 1;
  var LN = '\n',
      TAB = '  ';
  var indent = '';

  for (var i = 0; i < dep; i++) {
    indent += TAB;
  }

  var isArray = false,
      arrayLastIsObj = false;
  var str = '',
      prefix = '{',
      subfix = '}';

  if (Array.isArray(obj)) {
    isArray = true;
    prefix = '[';
    subfix = ']';
    str = obj.map(function (item, index) {
      var format = '';

      if (typeof item == 'function') {//
      } else if (_typeof(item) == 'object') {
        arrayLastIsObj = true;
        format = "".concat(LN).concat(indent).concat(formatConfig(item, dep + 1), ",");
      } else if (typeof item == 'number' && !isNaN(item) || typeof item == 'boolean') {
        format = "".concat(item, ",");
      } else if (typeof item == 'string') {
        format = "'".concat(item, "',");
      }

      if (index == obj.length - 1) {
        format = format.substring(0, format.length - 1);
      } else {
        arrayLastIsObj = false;
      }

      return format;
    }).join('');
  } else if (typeof obj != 'function' && _typeof(obj) == 'object') {
    str = Object.keys(obj).map(function (key, index, keys) {
      var val = obj[key];
      var format = '';

      if (typeof val == 'function') {//
      } else if (_typeof(val) == 'object') {
        format = "".concat(LN).concat(indent).concat(key, ": ").concat(formatConfig(val, dep + 1), ",");
      } else if (typeof val == 'number' && !isNaN(val) || typeof val == 'boolean') {
        format = "".concat(LN).concat(indent).concat(key, ": ").concat(val, ",");
      } else if (typeof val == 'string') {
        format = "".concat(LN).concat(indent).concat(key, ": '").concat(val, "',");
      }

      if (index == keys.length - 1) {
        format = format.substring(0, format.length - 1);
      }

      return format;
    }).join('');
  }

  var len = TAB.length;

  if (indent.length >= len) {
    indent = indent.substring(0, indent.length - len);
  }

  if (!isArray || arrayLastIsObj) {
    subfix = LN + indent + subfix;
  }

  return "".concat(prefix).concat(str).concat(subfix);
}

module.exports = {
  formatConfig: formatConfig
};