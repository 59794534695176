import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
var Group = {
  name: 'ImgCheckboxGroup',
  props: {
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    defaultValues: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      values: [],
      options: []
    };
  },
  provide: function provide() {
    return {
      groupContext: this
    };
  },
  watch: {
    'values': function values(value) {
      this.$emit('change', value); // // 此条件是为解决单选时，触发两次chang事件问题
      // if (!(newVal.length === 1 && oldVal.length === 1 && newVal[0] === oldVal[0])) {
      //   this.$emit('change', this.values)
      // }
    }
  },
  methods: {
    handleChange: function handleChange(option) {
      if (!option.checked) {
        if (this.values.indexOf(option.value) > -1) {
          this.values = this.values.filter(function (item) {
            return item != option.value;
          });
        }
      } else {
        if (!this.multiple) {
          this.values = [option.value];
          this.options.forEach(function (item) {
            if (item.value != option.value) {
              item.sChecked = false;
            }
          });
        } else {
          this.values.push(option.value);
        }
      }
    }
  },
  render: function render(h) {
    return h('div', {
      attrs: {
        style: 'display: flex'
      }
    }, [this.$slots.default]);
  }
};
export default {
  name: 'ImgCheckbox',
  Group: Group,
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    img: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    title: String
  },
  data: function data() {
    return {
      sChecked: this.initChecked()
    };
  },
  inject: ['groupContext'],
  watch: {
    'sChecked': function sChecked() {
      var option = {
        value: this.value,
        checked: this.sChecked
      };
      this.$emit('change', option);
      var groupContext = this.groupContext;

      if (groupContext) {
        groupContext.handleChange(option);
      }
    }
  },
  created: function created() {
    var groupContext = this.groupContext;

    if (groupContext) {
      this.sChecked = groupContext.defaultValues.length > 0 ? groupContext.defaultValues.indexOf(this.value) >= 0 : this.sChecked;
      groupContext.options.push(this);
    }
  },
  methods: {
    toggle: function toggle() {
      if (this.groupContext.multiple || !this.sChecked) {
        this.sChecked = !this.sChecked;
      }
    },
    initChecked: function initChecked() {
      var groupContext = this.groupContext;

      if (!groupContext) {
        return this.checked;
      } else if (groupContext.multiple) {
        return groupContext.defaultValues.indexOf(this.value) > -1;
      } else {
        return groupContext.defaultValues[0] == this.value;
      }
    }
  }
};