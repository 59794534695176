import "core-js/modules/es.object.to-string.js";
import { dataSource as ds } from "@/services";
export default {
  namespaced: true,
  state: {
    ysToken: null
  },
  getters: {
    ysToken: function ysToken(state) {
      var _state$ysToken, _state$ysToken2;

      // if (!state.ysToken) {
      //   try {
      //     const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
      //     state.user = JSON.parse(user)
      //   } catch (e) {
      //     console.error(e)
      //   }
      // }
      if (!state.ysToken) {
        return null;
      }

      var now = Date.now();

      if ((((_state$ysToken = state.ysToken) === null || _state$ysToken === void 0 ? void 0 : _state$ysToken.expire) - 24 * 60 * 60) * 1000 < now) {
        return null;
      }

      return (_state$ysToken2 = state.ysToken) === null || _state$ysToken2 === void 0 ? void 0 : _state$ysToken2.accessToken;
    }
  },
  mutations: {
    setYsToken: function setYsToken(state, playload) {
      state.ysToken = playload; // localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user))
    }
  },
  actions: {
    getYStoken: function getYStoken(context) {
      var flag = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      return new Promise(function (resolve, reject) {
        var token = context.getters.ysToken; // console.log("getYStoken", token);

        if (!token || flag) {
          ds.getAccessToken().then(function (res) {
            if (res.data.meta.success) {
              var data = res.data.data;
              context.commit("setYsToken", data);
              resolve(res.data.data.accessToken);
            } else {
              reject();
            }
          });
        } else {
          resolve(token);
        }
      });
    }
  }
};