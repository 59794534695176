import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import PageLayout from './PageLayout';
import PageToggleTransition from '../components/transition/PageToggleTransition';
import { mapState } from 'vuex';
export default {
  name: 'PageView',
  components: {
    PageToggleTransition: PageToggleTransition,
    PageLayout: PageLayout
  },
  data: function data() {
    return {
      page: {}
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('setting', ['isMobile', 'multiPage', 'animate'])), {}, {
    desc: function desc() {
      return this.page.desc;
    },
    linkList: function linkList() {
      return this.page.linkList;
    },
    extraImage: function extraImage() {
      return this.page.extraImage;
    }
  }),
  mounted: function mounted() {
    this.page = this.$refs.page;
  },
  updated: function updated() {
    this.page = this.$refs.page;
  }
};