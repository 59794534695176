var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "theme-color",
    style: {
      backgroundColor: _vm.color
    },
    on: {
      "click": _vm.toggle
    }
  }, [_vm.sChecked ? _c('a-icon', {
    attrs: {
      "type": "check"
    }
  }) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };