import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { hasAuthority } from '@/utils/authority-utils';
import { loginIgnore } from '@/router/index';
import { checkAuthorization } from '@/utils/request';
import NProgress from 'nprogress';
NProgress.configure({
  showSpinner: false
});
/**
 * 进度条开始
 * @param to
 * @param form
 * @param next
 */

var progressStart = function progressStart(to, from, next) {
  // start progress bar
  if (!NProgress.isStarted()) {
    NProgress.start();
  }

  next();
};
/**
 * 登录守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */


var loginGuard = function loginGuard(to, from, next, options) {
  var message = options.message;

  if (!loginIgnore.includes(to) && !checkAuthorization()) {
    message.warning('登录已失效，请重新登录');
    next({
      path: '/login'
    });
  } else {
    next();
  }
};
/**
 * 权限守卫
 * @param to
 * @param form
 * @param next
 * @param options
 */


var authorityGuard = function authorityGuard(to, from, next, options) {
  var store = options.store,
      message = options.message;
  var permissions = store.getters['account/permissions'];
  var roles = store.getters['account/roles'];

  if (!hasAuthority(to, permissions, roles)) {
    message.warning("\u5BF9\u4E0D\u8D77\uFF0C\u60A8\u65E0\u6743\u8BBF\u95EE\u9875\u9762: ".concat(to.fullPath, "\uFF0C\u8BF7\u8054\u7CFB\u7BA1\u7406\u5458"));
    next({
      path: '/403'
    }); // NProgress.done()
  } else {
    next();
  }
};
/**
 * 混合导航模式下一级菜单跳转重定向
 * @param to
 * @param from
 * @param next
 * @param options
 * @returns {*}
 */


var redirectGuard = function redirectGuard(to, from, next, options) {
  var store = options.store;

  var getFirstChild = function getFirstChild(routes) {
    var route = routes[0];

    if (!route.children || route.children.length === 0) {
      return route;
    }

    return getFirstChild(route.children);
  };

  if (store.state.setting.layout === 'mix') {
    var firstMenu = store.getters['setting/firstMenu'];

    if (firstMenu.find(function (item) {
      return item.fullPath === to.fullPath;
    })) {
      store.commit('setting/setActivatedFirst', to.fullPath);
      var subMenu = store.getters['setting/subMenu'];

      if (subMenu.length > 0) {
        var redirect = getFirstChild(subMenu);
        return next({
          path: redirect.fullPath
        });
      }
    }
  }

  next();
};
/**
 * 进度条结束
 * @param to
 * @param form
 * @param options
 */


var progressDone = function progressDone() {
  // finish progress bar
  NProgress.done();
};

export default {
  beforeEach: [progressStart, loginGuard, authorityGuard, redirectGuard],
  afterEach: [progressDone]
};