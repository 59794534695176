var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "page-layout"
  }, [_c('page-header', {
    ref: "pageHeader",
    style: "margin-top: ".concat(_vm.multiPage ? 0 : -24, "px"),
    attrs: {
      "breadcrumb": _vm.breadcrumb,
      "title": _vm.pageTitle,
      "logo": _vm.logo,
      "avatar": _vm.avatar
    }
  }, [_vm._t("action", null, {
    "slot": "action"
  }), _vm._t("headerContent", null, {
    "slot": "content"
  }), !this.$slots.headerContent && _vm.desc ? _c('div', {
    attrs: {
      "slot": "content"
    },
    slot: "content"
  }, [_c('p', [_vm._v(_vm._s(_vm.desc))]), this.linkList ? _c('div', {
    staticClass: "link"
  }, [_vm._l(_vm.linkList, function (link, index) {
    return [_c('a', {
      key: index,
      attrs: {
        "href": link.href
      }
    }, [_c('a-icon', {
      attrs: {
        "type": link.icon
      }
    }), _vm._v(_vm._s(link.title))], 1)];
  })], 2) : _vm._e()]) : _vm._e(), this.$slots.extra ? _vm._t("extra", null, {
    "slot": "extra"
  }) : _vm._e()], 2), _c('div', {
    ref: "page",
    class: ['page-content', _vm.layout, _vm.pageWidth]
  }, [_vm._t("default")], 2)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };