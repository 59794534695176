import "core-js/modules/es.object.to-string.js";
import Cookie from 'js-cookie'; // 401拦截

var resp401 = {
  /**
   * 响应数据之前做点什么
   * @param response 响应对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled: function onFulfilled(response, options) {
    var message = options.message;

    if (response.code === 401) {
      message.error('无此权限');
    }

    return response;
  },

  /**
   * 响应出错时执行
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected: function onRejected(error, options) {
    var message = options.message;
    var response = error.response;

    if (response.status === 401) {
      message.error('无此权限');
    }

    return Promise.reject(error);
  }
};
var resp403 = {
  onFulfilled: function onFulfilled(response, options) {
    var message = options.message;

    if (response.code === 403) {
      message.error('请求被拒绝');
    }

    return response;
  },
  onRejected: function onRejected(error, options) {
    var message = options.message;
    var response = error.response;

    if (response.status === 403) {
      message.error('请求被拒绝');
    }

    return Promise.reject(error);
  }
};
var reqCommon = {
  /**
   * 发送请求之前做些什么
   * @param config axios config
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {*}
   */
  onFulfilled: function onFulfilled(config, options) {
    var message = options.message;
    var url = config.url,
        xsrfCookieName = config.xsrfCookieName;

    if (url.indexOf('login') === -1 && xsrfCookieName && !Cookie.get(xsrfCookieName)) {
      message.warning('认证 token 已过期，请重新登录');
    }

    return config;
  },

  /**
   * 请求出错时做点什么
   * @param error 错误对象
   * @param options 应用配置 包含: {router, i18n, store, message}
   * @returns {Promise<never>}
   */
  onRejected: function onRejected(error, options) {
    var message = options.message;
    message.error(error.message);
    return Promise.reject(error);
  }
};
export default {
  request: [reqCommon],
  // 请求拦截
  response: [resp401, resp403] // 响应拦截

};