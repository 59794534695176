import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { enquireScreen } from "./utils/util";
import { mapState, mapMutations } from "vuex";
import themeUtil from "@/utils/themeUtil";
import { getI18nKey } from "@/utils/routerUtil";
import { IEVersion } from "@/utils/ieTest";
import { debounceTail } from "@/utils/debounce";
export default {
  name: "App",
  data: function data() {
    return {
      locale: {},
      resizeHandle: null
    };
  },
  created: function created() {
    var _this = this;

    this.setHtmlTitle();
    this.setLanguage(this.lang);
    enquireScreen(function (isMobile) {
      return _this.setDevice(isMobile);
    });
    this.$message.config({
      top: "100px",
      maxCount: 2
    });
    var iev = IEVersion();

    if (iev > 0) {
      this.setIeVersion(iev);
    }
  },
  mounted: function mounted() {
    var _this2 = this;

    this.setWeekModeTheme(this.weekMode);
    this.resizeHandle = debounceTail(function () {
      return _this2.resize();
    }, 799, this);
    window.addEventListener("resize", this.resizeHandle);
    this.checkScreen();
  },
  destroyed: function destroyed() {
    window.removeEventListener("resize", this.resizeHandle);
  },
  watch: {
    weekMode: function weekMode(val) {
      this.setWeekModeTheme(val);
    },
    lang: function lang(val) {
      this.setLanguage(val);
      this.setHtmlTitle();
    },
    $route: function $route() {
      this.setHtmlTitle();
    },
    "theme.mode": function themeMode(val) {
      var closeMessage = this.$message.loading("\u60A8\u9009\u62E9\u4E86\u4E3B\u9898\u6A21\u5F0F ".concat(val, ", \u6B63\u5728\u5207\u6362..."));
      themeUtil.changeThemeColor(this.theme.color, val).then(closeMessage);
    },
    "theme.color": function themeColor(val) {
      var closeMessage = this.$message.loading("\u60A8\u9009\u62E9\u4E86\u4E3B\u9898\u8272 ".concat(val, ", \u6B63\u5728\u5207\u6362..."));
      themeUtil.changeThemeColor(val, this.theme.mode).then(closeMessage);
    },
    layout: function layout() {
      window.dispatchEvent(new Event("resize"));
    }
  },
  computed: _objectSpread({}, mapState("setting", ["layout", "theme", "weekMode", "lang"])),
  methods: _objectSpread(_objectSpread({}, mapMutations("setting", ["setDevice", "setIeVersion"])), {}, {
    setWeekModeTheme: function setWeekModeTheme(weekMode) {
      if (weekMode) {
        document.body.classList.add("week-mode");
      } else {
        document.body.classList.remove("week-mode");
      }
    },
    setLanguage: function setLanguage(lang) {
      // this.$i18n.locale = lang
      // switch (lang) {
      //   case 'CN':
      //     this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
      //     break
      //   case 'HK':
      //     this.locale = require('ant-design-vue/es/locale-provider/zh_TW').default
      //     break
      //   case 'US':
      //     this.locale = require('ant-design-vue/es/locale-provider/en_US').default
      //     break
      //   default:
      //     this.locale = require('ant-design-vue/es/locale-provider/zh_CN').default
      //     break
      // }
      this.$i18n.locale = "CN";
      this.locale = require("ant-design-vue/es/locale-provider/zh_CN").default;
    },
    setHtmlTitle: function setHtmlTitle() {
      var route = this.$route;
      var key = route.path === "/" ? "home.name" : getI18nKey(route.matched[route.matched.length - 1].path);
      document.title = process.env.VUE_APP_NAME + " | " + this.$t(key);
    },
    popContainer: function popContainer() {
      return document.getElementById("popContainer");
    },
    checkScreen: function checkScreen() {
      var _this3 = this;

      var ratio = window.devicePixelRatio;
      var screen = window.screen;
      var showMsg = false;

      if (screen.availWidth !== 1920) {
        showMsg = true;
      } else if (ratio !== 1) {
        showMsg = true;
      }

      if (showMsg) {
        setTimeout(function () {
          var key = "open".concat(Date.now());

          _this3.$notification.open({
            message: "注意",
            duration: 6,
            description: "本系统设计为适应1920分辨率的屏幕（缩放倍率1.0），可使用浏览器的放大缩小页面功能调节以达到最佳显示效果。",
            // "本系统设计为1920分辨率的屏幕（缩放倍率1.0）达到最佳显示效果，可点击自动适应按钮让系统尝试自动调节页面。忽略此消息以采用当前显示，亦可使用浏览器的放大缩小页面功能手动调节。",
            btn: function btn(h) {
              return h("a-button", {
                props: {
                  type: "primary",
                  size: "small"
                },
                on: {
                  // click: () => this.autoResize(key),
                  click: function click() {
                    return _this3.$notification.close(key);
                  }
                }
              }, // "自动适应"
              "我知道了");
            },
            key: key
          });
        }, 997);
      }
    },
    autoResize: function autoResize(key) {
      var ratio = window.devicePixelRatio;
      var screen = window.screen;

      if (screen.availWidth !== 1920) {
        var rat = screen.availWidth / 1920;
        document.body.style.zoom = rat;
      } else if (ratio !== 1) {
        document.body.style.zoom = 1 / ratio;
      }

      this.$notification.close(key);
      this.$message.success("已自动适应屏幕。");
    },
    resize: function resize() {//
    }
  })
};