import { format } from "date-fns";
export default {
  namespaced: true,
  state: {
    timeRange: [format(new Date(Date.now() - 1000 * 7 * 60 * 60 * 24), "yyyy-MM-dd") + " 00:00:00", format(new Date(Date.now()), "yyyy-MM-dd") + " 23:59:59"],
    isRealTime: true
  },
  getters: {},
  mutations: {
    setTimeRange: function setTimeRange(state, time) {
      state.timeRange = time;
    },
    setIsRealTime: function setIsRealTime(state, isRealTime) {
      state.isRealTime = isRealTime;
    }
  }
};