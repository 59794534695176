import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { mapState, mapMutations } from 'vuex';
import { getI18nKey } from '@/utils/routerUtil';
export default {
  name: 'TabsHead',
  i18n: {
    messages: {
      CN: {
        lock: '点击锁定页签头',
        unlock: '点击解除锁定'
      },
      HK: {
        lock: '點擊鎖定頁簽頭',
        unlock: '點擊解除鎖定'
      },
      US: {
        lock: 'click to lock the tabs head',
        unlock: 'click to unlock'
      }
    }
  },
  props: {
    pageList: Array,
    active: String,
    fixed: Boolean
  },
  data: function data() {
    return {
      affixed: false
    };
  },
  inject: ['adminLayout'],
  created: function created() {
    this.affixed = this.fixedTabs;
  },
  computed: _objectSpread(_objectSpread({}, mapState('setting', ['layout', 'pageWidth', 'fixedHeader', 'fixedTabs', 'customTitles'])), {}, {
    lockTitle: function lockTitle() {
      return this.$t(this.fixedTabs ? 'unlock' : 'lock');
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('setting', ['setFixedTabs'])), {}, {
    onLockClick: function onLockClick() {
      var _this = this;

      this.setFixedTabs(!this.fixedTabs);

      if (this.fixedTabs) {
        setTimeout(function () {
          _this.affixed = true;
        }, 200);
      } else {
        this.affixed = false;
      }
    },
    onTabClick: function onTabClick(key) {
      if (this.active !== key) {
        this.$emit('change', key);
      }
    },
    onClose: function onClose(key) {
      this.$emit('close', key);
    },
    onRefresh: function onRefresh(page) {
      this.$emit('refresh', page.fullPath, page);
    },
    onContextmenu: function onContextmenu(pageKey, e) {
      this.$emit('contextmenu', pageKey, e);
    },
    pageName: function pageName(page) {
      var pagePath = page.fullPath.split('?')[0];
      var custom = this.customTitles.find(function (item) {
        return item.path === pagePath;
      });
      return custom && custom.title || page.title || this.$t(getI18nKey(page.keyPath));
    }
  })
};