import _typeof from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
var TabsPagePlugin = {
  install: function install(Vue) {
    Vue.mixin({
      methods: {
        $closePage: function $closePage(closeRoute, nextRoute) {
          var event = new CustomEvent('page:close', {
            detail: {
              closeRoute: closeRoute,
              nextRoute: nextRoute
            }
          });
          window.dispatchEvent(event);
        },
        $refreshPage: function $refreshPage(route) {
          var path = _typeof(route) === 'object' ? route.path : route;
          var event = new CustomEvent('page:refresh', {
            detail: {
              pageKey: path
            }
          });
          window.dispatchEvent(event);
        },
        $openPage: function $openPage(route, title) {
          this.$setPageTitle(route, title);
          this.$router.push(route);
        },
        $setPageTitle: function $setPageTitle(route, title) {
          if (title) {
            var path = _typeof(route) === 'object' ? route.path : route;
            path = path && path.split('?')[0];
            this.$store.commit('setting/setCustomTitle', {
              path: path,
              title: title
            });
          }
        }
      },
      computed: {
        customTitle: function customTitle() {
          var customTitles = this.$store.state.setting.customTitles;
          var path = this.$route.path.split('?')[0];
          var custom = customTitles.find(function (item) {
            return item.path === path;
          });
          return custom && custom.title;
        }
      }
    });
  }
};
export default TabsPagePlugin;