import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
// 语句模式
var MODE = {
  STATEMENTS: 's',
  //语句模式
  PHRASAL: 'p' //词组模式

};
var VueI18nPlugin = {
  install: function install(Vue) {
    Vue.mixin({
      methods: {
        $ta: function $ta(syntaxKey, mode) {
          var _mode = mode || MODE.STATEMENTS;

          var keys = syntaxKey.split('|');

          var _this = this;

          var locale = this.$i18n.locale;
          var message = '';
          var splitter = locale == 'US' ? ' ' : ''; // 拼接 message

          keys.forEach(function (key) {
            message += _this.$t(key) + splitter;
          }); // 英文环境语句模式下，转换单词大小写

          if (keys.length > 0 && _mode == MODE.STATEMENTS && locale == 'US') {
            message = message.charAt(0).toUpperCase() + message.toLowerCase().substring(1);
          }

          return message;
        }
      }
    });
  }
};
export default VueI18nPlugin;