import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { preset as animates } from '@/config/default/animate.config';
export default {
  name: 'PageToggleTransition',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    animate: {
      type: String,
      validator: function validator(value) {
        return animates.findIndex(function (item) {
          return item.name == value;
        }) != -1;
      },
      default: 'bounce'
    },
    direction: {
      type: String,
      validator: function validator(value) {
        return ['x', 'y', 'left', 'right', 'up', 'down', 'downLeft', 'upRight', 'downRight', 'upLeft', 'downBig', 'upBig', 'downLeft', 'downRight', 'topRight', 'bottomLeft', 'topLeft', 'bottomRight', 'default'].indexOf(value) > -1;
      }
    },
    reverse: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    enterAnimate: function enterAnimate() {
      return this.activeClass(false);
    },
    leaveAnimate: function leaveAnimate() {
      return this.activeClass(true);
    }
  },
  methods: {
    activeClass: function activeClass(isLeave) {
      var _this = this;

      var animate = animates.find(function (item) {
        return _this.animate == item.name;
      });

      if (animate == undefined) {
        return '';
      }

      var direction = '';

      if (this.direction == undefined) {
        direction = animate.directions[0];
      } else {
        direction = animate.directions.find(function (item) {
          return item == _this.direction;
        });
      }

      direction = direction == undefined || direction === 'default' ? '' : direction;

      if (direction != '') {
        direction = isLeave && this.reverse ? this.reversePosition(direction, animate.directions) : direction;
        direction = direction[0].toUpperCase() + direction.substring(1);
      }

      var t = isLeave ? 'Out' : 'In';
      return animate.name + t + direction;
    },
    reversePosition: function reversePosition(direction, directions) {
      if (direction.length == 0 || direction == 'x' || direction == 'y') {
        return direction;
      }

      var index = directions.indexOf(direction);
      index = index % 2 == 1 ? index - 1 : index + 1;
      return directions[index];
    }
  }
};