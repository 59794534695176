export default {
  name: 'HeaderSearch',
  data: function data() {
    return {
      dataSource: ['选项一', '选项二'],
      searchMode: false
    };
  },
  methods: {
    enterSearchMode: function enterSearchMode() {
      var _this = this;

      this.searchMode = true;
      this.$emit('active', true);
      setTimeout(function () {
        return _this.$refs.input.focus();
      }, 300);
    },
    leaveSearchMode: function leaveSearchMode() {
      var _this2 = this;

      this.searchMode = false;
      setTimeout(function () {
        return _this2.$emit('active', false);
      }, 300);
    }
  }
};