import "G:\\worker\\DeviceCloud\\vue-antd-admin\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "G:\\worker\\DeviceCloud\\vue-antd-admin\\node_modules\\core-js\\modules\\es.promise.js";
import "G:\\worker\\DeviceCloud\\vue-antd-admin\\node_modules\\core-js\\modules\\es.object.assign.js";
import "G:\\worker\\DeviceCloud\\vue-antd-admin\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import App from './App.vue';
import { initRouter } from './router';
import './theme/index.less';
import Antd from 'ant-design-vue';
import Viser from 'viser-vue';
import store from './store';
import 'animate.css/source/animate.css';
import Plugins from '@/plugins';
import { initI18n } from '@/utils/i18n';
import bootstrap from '@/bootstrap';
import 'moment/locale/zh-cn'; // import VueCompositionAPI from '@vue/composition-api'

import BaiduMap from 'vue-baidu-map';

if (process.env.VUE_APP_MOCK === '1') {
  import('@/mock');
}

var router = initRouter(store.state.setting.asyncRoutes);
var i18n = initI18n('CN', 'US');
Vue.use(Antd);
Vue.config.productionTip = false;
Vue.use(Viser);
Vue.use(Plugins); // Vue.use(VueCompositionAPI)

Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: 'ObXKAY9dQhKpBPDN4oLnYpHSB5UhfOnY' // ak: 'FGN0vQ910ykze90ZGBtIVSANDILkNkg0'

});
bootstrap({
  router: router,
  store: store,
  i18n: i18n,
  message: Vue.prototype.$message
});
new Vue({
  router: router,
  store: store,
  i18n: i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');