import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

/* eslint-disable no-unused-vars */
import TabsView from '@/layouts/tabs/TabsView';
import BlankView from '@/layouts/BlankView';
import PageView from '@/layouts/PageView'; // 路由配置

var options = {
  routes: [{
    path: '/login',
    name: '登录页',
    component: function component() {
      return import('@/pages/login');
    }
  }, {
    path: '*',
    name: '404',
    component: function component() {
      return import('@/pages/exception/404');
    }
  }, {
    path: '/403',
    name: '403',
    component: function component() {
      return import('@/pages/exception/403');
    }
  }, {
    path: '/',
    name: '首页',
    component: TabsView,
    // redirect: '/login',
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: '首页',
      meta: {
        icon: 'dashboard',
        page: {
          cacheAble: true
        }
      },
      // component: BlankView,
      // children: [
      //   // {
      //   //   path: 'workplace',
      //   //   name: '工作台',
      //   //   meta: {
      //   //     page: {
      //   //       closable: false
      //   //     }
      //   //   },
      //   //   component: () => import('@/pages/dashboard/workplace'),
      //   // },
      //   {
      //     path: 'analysis',
      //     name: '分析页',
      //     component: () => import('@/pages/dashboard/analysis'),
      //   }
      // ]
      // component: () => import('@/pages/dashboard/dashboard'),
      component: function component() {
        return import('@/pages/dashboard/userboard');
      }
    }, {
      path: 'devices',
      name: '设备管理',
      meta: {
        icon: 'form',
        page: {
          cacheAble: false
        }
      },
      component: BlankView,
      children: [{
        path: '/',
        name: '设备列表',
        meta: {
          invisible: 'true',
          page: {
            cacheAble: false,
            breadcrumb: ['首页', '设备管理', '设备列表']
          }
        },
        component: function component() {
          return import('@/pages/device');
        }
      }, // {
      //   path: '/devices/add',
      //   name: '添加设备',
      //   meta: {
      //     invisible: 'true',
      //     page: {
      //       cacheAble: false,
      //       breadcrumb: ['首页', '设备管理', '添加设备']
      //     }
      //   },
      //   component: () => import('@/pages/device/add'),
      // },
      {
        path: '/devices/:id/detail',
        name: '查看设备',
        meta: {
          invisible: 'true',
          page: {
            cacheAble: false,
            breadcrumb: ['首页', '设备管理', '查看设备']
          }
        },
        component: function component() {
          return import('@/pages/device/detail');
        }
      }]
    }, {
      path: 'user',
      name: '用户管理',
      meta: {
        icon: 'form',
        page: {
          cacheAble: false,
          breadcrumb: ['首页', '系统设置', '用户管理']
        }
      },
      component: function component() {
        return import('@/pages/system/user');
      }
    }, {
      path: 'model',
      name: '型号管理',
      meta: {
        icon: 'form',
        page: {
          cacheAble: false,
          breadcrumb: ['首页', '系统设置', '型号管理']
        }
      },
      component: function component() {
        return import('@/pages/system/model');
      }
    } // {
    //   path: 'system',
    //   name: '系统设置',
    //   meta: {
    //     icon: 'form',
    //     page: {
    //       cacheAble: false
    //     }
    //   },
    //   component: PageView,
    //   redirect: '/system/user',
    //   children: [
    //     {
    //       path: 'user',
    //       name: '用户管理',
    //       page: {
    //         cacheAble: false,
    //         breadcrumb: ['首页', '系统设置', '用户管理']
    //       },
    //       component: () => import('@/pages/system/user'),
    //     },
    //     {
    //       path: 'model',
    //       name: '型号管理',
    //       component: () => import('@/pages/system/model'),
    //     },
    //     {
    //       path: 'user/add',
    //       name: '添加用户',
    //       meta: {
    //         invisible: 'true',
    //         page: {
    //           cacheAble: false,
    //           breadcrumb: ['首页', '系统设置', '用户管理', '添加用户']
    //         }
    //       },
    //       component: () => import('@/pages/system/user/add'),
    //     },
    //     {
    //       path: 'user/view',
    //       name: '查看用户',
    //       meta: {
    //         invisible: 'true',
    //         page: {
    //           cacheAble: false,
    //           breadcrumb: ['首页', '系统设置', '用户管理', '查看用户']
    //         }
    //       },
    //       component: () => import('@/pages/system/user/view'),
    //     }
    //   ]
    // },
    // {
    //   path: 'view',
    //   name: '演示页',
    //   meta: {
    //     icon: 'form',
    //     page: {
    //       cacheAble: false
    //     }
    //   },
    //   component: BlankView,
    //   children: [
    //     {
    //       path: 'workplace',
    //       name: '工作台',
    //       meta: {
    //         page: {
    //           closable: false
    //         }
    //       },
    //       component: () => import('@/pages/dashboard/workplace'),
    //     },
    //     {
    //       path: 'analysis',
    //       name: '分析页',
    //       component: () => import('@/pages/dashboard/analysis'),
    //     }
    //   ]
    // },
    // {
    //   path: 'form',
    //   name: '表单页',
    //   meta: {
    //     icon: 'form',
    //     page: {
    //       cacheAble: false
    //     }
    //   },
    //   component: PageView,
    //   children: [
    //     {
    //       path: 'basic',
    //       name: '基础表单',
    //       component: () => import('@/pages/form/basic'),
    //     },
    //     {
    //       path: 'step',
    //       name: '分步表单',
    //       component: () => import('@/pages/form/step'),
    //     },
    //     {
    //       path: 'advance',
    //       name: '高级表单',
    //       component: () => import('@/pages/form/advance'),
    //     }
    //   ]
    // },
    // {
    //   path: 'list',
    //   name: '列表页',
    //   meta: {
    //     icon: 'table'
    //   },
    //   component: PageView,
    //   children: [
    //     {
    //       path: 'query',
    //       name: '查询表格',
    //       meta: {
    //         authority: 'queryForm',
    //       },
    //       component: () => import('@/pages/list/QueryList'),
    //     },
    //     {
    //       path: 'primary',
    //       name: '标准列表',
    //       component: () => import('@/pages/list/StandardList'),
    //     },
    //     {
    //       path: 'card',
    //       name: '卡片列表',
    //       component: () => import('@/pages/list/CardList'),
    //     },
    //     {
    //       path: 'search',
    //       name: '搜索列表',
    //       component: () => import('@/pages/list/search/SearchLayout'),
    //       children: [
    //         {
    //           path: 'article',
    //           name: '文章',
    //           component: () => import('@/pages/list/search/ArticleList'),
    //         },
    //         {
    //           path: 'application',
    //           name: '应用',
    //           component: () => import('@/pages/list/search/ApplicationList'),
    //         },
    //         {
    //           path: 'project',
    //           name: '项目',
    //           component: () => import('@/pages/list/search/ProjectList'),
    //         }
    //       ]
    //     }
    //   ]
    // },
    // {
    //   path: 'details',
    //   name: '详情页',
    //   meta: {
    //     icon: 'profile'
    //   },
    //   component: BlankView,
    //   children: [
    //     {
    //       path: 'basic',
    //       name: '基础详情页',
    //       component: () => import('@/pages/detail/BasicDetail')
    //     },
    //     {
    //       path: 'advance',
    //       name: '高级详情页',
    //       component: () => import('@/pages/detail/AdvancedDetail')
    //     }
    //   ]
    // },
    // {
    //   path: 'result',
    //   name: '结果页',
    //   meta: {
    //     icon: 'check-circle-o',
    //   },
    //   component: PageView,
    //   children: [
    //     {
    //       path: 'success',
    //       name: '成功',
    //       component: () => import('@/pages/result/Success')
    //     },
    //     {
    //       path: 'error',
    //       name: '失败',
    //       component: () => import('@/pages/result/Error')
    //     }
    //   ]
    // },
    // {
    //   path: 'exception',
    //   name: '异常页',
    //   meta: {
    //     icon: 'warning',
    //   },
    //   component: BlankView,
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Exp404',
    //       component: () => import('@/pages/exception/404')
    //     },
    //     {
    //       path: '403',
    //       name: 'Exp403',
    //       component: () => import('@/pages/exception/403')
    //     },
    //     {
    //       path: '500',
    //       name: 'Exp500',
    //       component: () => import('@/pages/exception/500')
    //     }
    //   ]
    // },
    // {
    //   path: 'components',
    //   name: '内置组件',
    //   meta: {
    //     icon: 'appstore-o'
    //   },
    //   component: PageView,
    //   children: [
    //     {
    //       path: 'taskCard',
    //       name: '任务卡片',
    //       component: () => import('@/pages/components/TaskCard')
    //     },
    //     {
    //       path: 'palette',
    //       name: '颜色复选框',
    //       component: () => import('@/pages/components/Palette')
    //     },
    //     {
    //       path: 'table',
    //       name: '高级表格1',
    //       component: () => import('@/pages/components/table')
    //     }
    //   ]
    // },
    // {
    //   name: '验权表单',
    //   path: 'auth/form',
    //   meta: {
    //     icon: 'file-excel',
    //     authority: {
    //       permission: 'form'
    //     }
    //   },
    //   component: () => import('@/pages/form/basic')
    // },
    // {
    //   name: 'Ant Design Vue',
    //   path: 'antdv',
    //   meta: {
    //     icon: 'ant-design',
    //     link: 'https://www.antdv.com/docs/vue/introduce-cn/'
    //   }
    // },
    // {
    //   name: '使用文档',
    //   path: 'document',
    //   meta: {
    //     icon: 'file-word',
    //     link: 'https://iczer.gitee.io/vue-antd-admin-docs/'
    //   }
    // }
    ]
  }]
};
export default options;