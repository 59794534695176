var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "footer hdbackgroundImage"
  }, [_c('div', {
    staticClass: "copyright_rest"
  }), _c('div', {
    staticClass: "copyright hdbackgroundImage"
  }, [_vm._v(" Copyright"), _c('a-icon', {
    attrs: {
      "type": "copyright"
    }
  }), _vm._v(_vm._s(_vm.copyright) + " ")], 1), _c('div', {
    staticClass: "copyright_rest"
  })]);
};

var staticRenderFns = [];
export { render, staticRenderFns };