import "core-js/modules/es.json.stringify.js";
export default {
  namespaced: true,
  state: {
    user: undefined,
    permissions: null,
    roles: null,
    routesConfig: null
  },
  getters: {
    user: function user(state) {
      if (!state.user) {
        try {
          var user = localStorage.getItem(process.env.VUE_APP_USER_KEY);
          state.user = JSON.parse(user);
        } catch (e) {}
      }

      return state.user;
    },
    permissions: function permissions(state) {
      if (!state.permissions) {
        try {
          var permissions = localStorage.getItem(process.env.VUE_APP_PERMISSIONS_KEY);
          state.permissions = JSON.parse(permissions);
          state.permissions = state.permissions ? state.permissions : [];
        } catch (e) {}
      }

      return state.permissions;
    },
    roles: function roles(state) {
      if (!state.roles) {
        try {
          var roles = localStorage.getItem(process.env.VUE_APP_ROLES_KEY);
          state.roles = JSON.parse(roles);
          state.roles = state.roles ? state.roles : [];
        } catch (e) {}
      }

      return state.roles;
    },
    routesConfig: function routesConfig(state) {
      if (!state.routesConfig) {
        try {
          var routesConfig = localStorage.getItem(process.env.VUE_APP_ROUTES_KEY);
          state.routesConfig = JSON.parse(routesConfig);
          state.routesConfig = state.routesConfig ? state.routesConfig : [];
        } catch (e) {}
      }

      return state.routesConfig;
    }
  },
  mutations: {
    setUser: function setUser(state, user) {
      state.user = user;
      localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user));
    },
    setPermissions: function setPermissions(state, permissions) {
      state.permissions = permissions;
      localStorage.setItem(process.env.VUE_APP_PERMISSIONS_KEY, JSON.stringify(permissions));
    },
    setRoles: function setRoles(state, roles) {
      state.roles = roles;
      localStorage.setItem(process.env.VUE_APP_ROLES_KEY, JSON.stringify(roles));
    },
    setRoutesConfig: function setRoutesConfig(state, routesConfig) {
      state.routesConfig = routesConfig;
      localStorage.setItem(process.env.VUE_APP_ROUTES_KEY, JSON.stringify(routesConfig));
    }
  }
};