var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return !_vm.disabled ? _c('transition', {
    attrs: {
      "enter-active-class": "animated ".concat(_vm.enterAnimate, " page-toggle-enter-active"),
      "leave-active-class": "animated ".concat(_vm.leaveAnimate, " page-toggle-leave-active")
    }
  }, [_vm._t("default")], 2) : _c('div', [_vm._t("default")], 2);
};

var staticRenderFns = [];
export { render, staticRenderFns };