export default {
  name: 'Drawer',
  data: function data() {
    return {};
  },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    placement: {
      type: String,
      required: false,
      default: 'left'
    },
    showHandler: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    open: function open() {
      this.$emit('change', true);
    },
    close: function close() {
      this.$emit('change', false);
    },
    toggle: function toggle() {
      this.$emit('change', !this.visible);
    }
  }
};