import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import enquireJs from 'enquire.js';
export function isDef(v) {
  return v !== undefined && v !== null;
}
/**
 * Remove an item from an array.
 */

export function remove(arr, item) {
  if (arr.length) {
    var index = arr.indexOf(item);

    if (index > -1) {
      return arr.splice(index, 1);
    }
  }
}
export function isRegExp(v) {
  return _toString.call(v) === '[object RegExp]';
}
export function enquireScreen(call) {
  var handler = {
    match: function match() {
      call && call(true);
    },
    unmatch: function unmatch() {
      call && call(false);
    }
  };
  enquireJs.register('only screen and (max-width: 767.99px)', handler);
}
var _toString = Object.prototype.toString;