
import {request, METHOD} from '@/utils/request'

export async function deviceCapture(params) {
  return request('https://open.ys7.com/api/lapp/device/capture', METHOD.POST, params, {
    withCredentials: false,
  });
}

export default {
  deviceCapture
}
