import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
import { mapGetters } from 'vuex';
import PopupForm from "./PopupForm.vue";
export default {
  components: {
    PopupForm: PopupForm
  },
  name: "changepsw",
  props: {
    title: {
      type: String,
      default: "修改密码"
    },
    visible: {
      type: Boolean,
      default: false
    },
    formdata: {
      type: Object,
      default: function _default() {
        return {
          password: "",
          newpassword: "",
          newpasswordrepeat: ""
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread({}, mapGetters('account', ['user'])),
  beforeCreate: function beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    submitForm: function submitForm() {
      var _this = this;

      this.form.validateFields(function (err, values) {
        if (err) {
          return;
        } // let pas = this.form.getFieldValue("changepswmodel.newpassword");
        // let pas2 = this.form.getFieldValue("changepswmodel.newpasswordrepeat");
        // if (pas !== pas2) {
        //   this.form.setFields({
        //     "changepswmodel.newpasswordrepeat": {
        //       errors: ['密码不一致'],
        //     },
        //   });
        //   return;
        // }


        ds.updatePassword({
          "appId": _this.user.username,
          "newPassword": values.changepswmodel.newpassword,
          "oldPassword": values.changepswmodel.password
        }).then(function (res) {
          if (res.data.meta.success) {
            _this.$message.success('修改成功');

            _this.$emit("create");
          } else {
            _this.$message.error('修改失败： ' + res.data.meta.msg);
          }
        }).catch(function (err) {});
      });
    },
    validatecn: function validatecn(rule, value, f) {
      // console.log("validate", value);
      var pas = this.form.getFieldValue("changepswmodel.password");

      if (value === pas) {
        f("不能与旧密码相同");
      }

      f();
    },
    validaterp: function validaterp(rule, value, f) {
      // console.log("validate", value);
      var pas = this.form.getFieldValue("changepswmodel.newpassword");

      if (value !== pas) {
        f("密码不一致");
      }

      f();
    }
  }
};