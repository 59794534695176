/**
 * 给对象注入属性
 * @param keys 属性key数组， 如 keys = ['config', 'path'] , 则会给对象注入 object.config.path 的属性
 * @param value 属性值
 * @returns {Object}
 */
Object.defineProperty(Object.prototype, 'assignProps', {
  writable: false,
  enumerable: false,
  configurable: true,
  value: function value(keys, _value) {
    var props = this;

    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];

      if (i == keys.length - 1) {
        props[key] = _value;
      } else {
        props[key] = props[key] == undefined ? {} : props[key];
        props = props[key];
      }
    }

    return this;
  }
});