import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import IMenu from './menu';
import { mapState } from 'vuex';
export default {
  name: 'SideMenu',
  components: {
    IMenu: IMenu
  },
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: false
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    }
  },
  computed: _objectSpread({
    sideTheme: function sideTheme() {
      return this.theme == 'light' ? this.theme : 'dark';
    }
  }, mapState('setting', ['isMobile', 'systemName'])),
  methods: {
    onSelect: function onSelect(obj) {
      this.$emit('menuSelect', obj);
    }
  }
};