import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('page-layout', {
    attrs: {
      "desc": _vm.desc,
      "linkList": _vm.linkList
    }
  }, [this.extraImage && !_vm.isMobile ? _c('div', {
    staticClass: "extraImg",
    attrs: {
      "slot": "extra"
    },
    slot: "extra"
  }, [_c('img', {
    attrs: {
      "src": _vm.extraImage
    }
  })]) : _vm._e(), _c('page-toggle-transition', {
    attrs: {
      "disabled": _vm.animate.disabled,
      "animate": _vm.animate.name,
      "direction": _vm.animate.direction
    }
  }, [_c('router-view', {
    ref: "page"
  })], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };