import VueI18nPlugin from './i18n-extend';
import AuthorityPlugin from './authority-plugin';
import TabsPagePlugin from './tabs-page-plugin';
var Plugins = {
  install: function install(Vue) {
    Vue.use(VueI18nPlugin);
    Vue.use(AuthorityPlugin);
    Vue.use(TabsPagePlugin);
  }
};
export default Plugins;