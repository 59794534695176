import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import PageHeader from '@/components/page/header/PageHeader';
import { mapState, mapMutations } from 'vuex';
import { getI18nKey } from '@/utils/routerUtil';
export default {
  name: 'PageLayout',
  components: {
    PageHeader: PageHeader
  },
  props: ['desc', 'logo', 'title', 'avatar', 'linkList', 'extraImage'],
  data: function data() {
    return {
      page: {},
      pageHeaderHeight: 0
    };
  },
  watch: {
    $route: function $route() {
      this.page = this.$route.meta.page;
    }
  },
  updated: function updated() {
    if (!this._inactive) {
      this.updatePageHeight();
    }
  },
  activated: function activated() {
    this.updatePageHeight();
  },
  deactivated: function deactivated() {
    this.updatePageHeight(0);
  },
  mounted: function mounted() {
    this.updatePageHeight();
  },
  created: function created() {
    this.page = this.$route.meta.page;
  },
  beforeDestroy: function beforeDestroy() {
    this.updatePageHeight(0);
  },
  computed: _objectSpread(_objectSpread({}, mapState('setting', ['layout', 'multiPage', 'pageMinHeight', 'pageWidth', 'customTitles'])), {}, {
    pageTitle: function pageTitle() {
      var pageTitle = this.page && this.page.title;
      return this.customTitle || pageTitle && this.$t(pageTitle) || this.title || this.routeName;
    },
    routeName: function routeName() {
      var route = this.$route;
      return this.$t(getI18nKey(route.matched[route.matched.length - 1].path));
    },
    breadcrumb: function breadcrumb() {
      var _this = this;

      var page = this.page;
      var breadcrumb = page && page.breadcrumb;

      if (breadcrumb) {
        var i18nBreadcrumb = [];
        breadcrumb.forEach(function (item) {
          i18nBreadcrumb.push(_this.$t(item));
        });
        return i18nBreadcrumb;
      } else {
        return this.getRouteBreadcrumb();
      }
    },
    marginCorrect: function marginCorrect() {
      return this.multiPage ? 24 : 0;
    }
  }),
  methods: _objectSpread(_objectSpread({}, mapMutations('setting', ['correctPageMinHeight'])), {}, {
    getRouteBreadcrumb: function getRouteBreadcrumb() {
      var _this2 = this;

      var routes = this.$route.matched;
      var breadcrumb = [];
      routes.forEach(function (route) {
        var path = route.path.length === 0 ? '/home' : route.path;
        breadcrumb.push(_this2.$t(getI18nKey(path)));
      });
      var pageTitle = this.page && this.page.title;

      if (this.customTitle || pageTitle) {
        breadcrumb[breadcrumb.length - 1] = this.customTitle || pageTitle;
      }

      return breadcrumb;
    },

    /**
     * 用于计算页面内容最小高度
     * @param newHeight
     */
    updatePageHeight: function updatePageHeight() {
      var newHeight = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.$refs.pageHeader.$el.offsetHeight + this.marginCorrect;
      this.correctPageMinHeight(this.pageHeaderHeight - newHeight);
      this.pageHeaderHeight = newHeight;
    }
  })
};