export default {
  name: 'Contextmenu',
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false
    },
    itemList: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      left: 0,
      top: 0,
      target: null,
      meta: null,
      selectedKeys: []
    };
  },
  computed: {
    style: function style() {
      return {
        left: this.left + 'px',
        top: this.top + 'px'
      };
    }
  },
  created: function created() {
    window.addEventListener('click', this.closeMenu);
    window.addEventListener('contextmenu', this.setPosition);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('click', this.closeMenu);
    window.removeEventListener('contextmenu', this.setPosition);
  },
  methods: {
    closeMenu: function closeMenu() {
      this.$emit('update:visible', false);
    },
    setPosition: function setPosition(e) {
      this.left = e.clientX;
      this.top = e.clientY;
      this.target = e.target;
      this.meta = e.meta;
    },
    handleClick: function handleClick(_ref) {
      var key = _ref.key;
      this.$emit('select', key, this.target, this.meta);
      this.closeMenu();
    }
  }
};