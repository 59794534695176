var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-layout-sider', {
    class: ['side-menu', 'beauty-scroll', _vm.isMobile ? null : 'shadow'],
    attrs: {
      "theme": _vm.sideTheme,
      "width": "256px",
      "collapsible": _vm.collapsible,
      "trigger": null
    },
    model: {
      value: _vm.collapsed,
      callback: function callback($$v) {
        _vm.collapsed = $$v;
      },
      expression: "collapsed"
    }
  }, [_c('div', {
    class: ['logo', _vm.theme]
  }, [_c('router-link', {
    attrs: {
      "to": "/dashboard/workplace"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/img/logo.png")
    }
  }), _c('h1', [_vm._v(_vm._s(_vm.systemName))])])], 1), _c('i-menu', {
    staticClass: "menu",
    attrs: {
      "theme": _vm.theme,
      "collapsed": _vm.collapsed,
      "options": _vm.menuData
    },
    on: {
      "select": _vm.onSelect
    }
  })], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };