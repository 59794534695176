import _createForOfIteratorHelper from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";

/**
 * 获取路由需要的权限
 * @param permissions
 * @param route
 * @returns {Permission}
 */
var getRoutePermission = function getRoutePermission(permissions, route) {
  return permissions.find(function (item) {
    return item.id === route.meta.authority.permission;
  });
};
/**
 * 获取路由需要的角色
 * @param roles
 * @param route
 * @returns {Array[Role]}
 */


var getRouteRole = function getRouteRole(roles, route) {
  var requiredRoles = route.meta.authority.role;
  return requiredRoles ? roles.filter(function (item) {
    return requiredRoles.findIndex(function (required) {
      return required === item.id;
    }) !== -1;
  }) : [];
};
/**
 * 判断是否已为方法注入权限认证
 * @param method
 * @returns {boolean}
 */


var hasInjected = function hasInjected(method) {
  return method.toString().indexOf('//--auth-inject') !== -1;
};
/**
 * 操作权限校验
 * @param authConfig
 * @param permission
 * @param role
 * @param permissions
 * @param roles
 * @returns {boolean}
 */


var auth = function auth(authConfig, permission, role, permissions, roles) {
  var check = authConfig.check,
      type = authConfig.type;

  if (check && typeof check === 'function') {
    return check.apply(this, [permission, role, permissions, roles]);
  }

  if (type === 'permission') {
    return checkFromPermission(check, permission);
  } else if (type === 'role') {
    return checkFromRoles(check, role);
  } else {
    return checkFromPermission(check, permission) || checkFromRoles(check, role);
  }
};
/**
 * 检查权限是否有操作权限
 * @param check 需要检查的操作权限
 * @param permission 权限
 * @returns {boolean}
 */


var checkFromPermission = function checkFromPermission(check, permission) {
  return permission && permission.operation && permission.operation.indexOf(check) !== -1;
};
/**
 * 检查 roles 是否有操作权限
 * @param check 需要检查的操作权限
 * @param roles 角色数组
 * @returns {boolean}
 */


var checkFromRoles = function checkFromRoles(check, roles) {
  if (!roles) {
    return false;
  }

  var _iterator = _createForOfIteratorHelper(roles),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var role = _step.value;
      var operation = role.operation;

      if (operation && operation.indexOf(check) !== -1) {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return false;
};

var checkInject = function checkInject(el, binding, vnode) {
  var type = binding.arg;
  var check = binding.value;
  var instance = vnode.context;
  var $auth = instance.$auth;

  if (!$auth || !$auth(check, type)) {
    addDisabled(el);
  } else {
    removeDisabled(el);
  }
};

var addDisabled = function addDisabled(el) {
  if (el.tagName === 'BUTTON') {
    el.disabled = true;
  } else {
    el.classList.add('disabled');
  }

  el.setAttribute('title', '无此权限');
};

var removeDisabled = function removeDisabled(el) {
  el.disabled = false;
  el.classList.remove('disabled');
  el.removeAttribute('title');
};

var AuthorityPlugin = {
  install: function install(Vue) {
    Vue.directive('auth', {
      bind: function bind(el, binding, vnode) {
        setTimeout(function () {
          return checkInject(el, binding, vnode);
        }, 10);
      },
      componentUpdated: function componentUpdated(el, binding, vnode) {
        setTimeout(function () {
          return checkInject(el, binding, vnode);
        }, 10);
      },
      unbind: function unbind(el) {
        removeDisabled(el);
      }
    });
    Vue.mixin({
      beforeCreate: function beforeCreate() {
        var _this = this;

        if (this.$options.authorize) {
          var authorize = this.$options.authorize;
          Object.keys(authorize).forEach(function (key) {
            if (_this.$options.methods[key]) {
              var method = _this.$options.methods[key];

              if (!hasInjected(method)) {
                var authConfig = authorize[key];
                authConfig = typeof authConfig === 'string' ? {
                  check: authConfig
                } : authConfig;
                var _authConfig = authConfig,
                    check = _authConfig.check,
                    type = _authConfig.type,
                    onFailure = _authConfig.onFailure;

                _this.$options.methods[key] = function () {
                  //--auth-inject
                  if (this.$auth(check, type)) {
                    return method.apply(this, arguments);
                  } else {
                    if (onFailure && typeof onFailure === 'function') {
                      this["$".concat(check, "Failure")] = onFailure;
                      return this["$".concat(check, "Failure")](check);
                    } else {
                      this.$message.error("\u5BF9\u4E0D\u8D77\uFF0C\u60A8\u6CA1\u6709\u64CD\u4F5C\u6743\u9650\uFF1A".concat(check));
                    }

                    return 0;
                  }
                };
              }
            }
          });
        }
      },
      methods: {
        /**
         * 操作权限校验
         * @param check 需要校验的操作名
         * @param type 校验类型，通过 permission 校验，还是通过 role 校验。
         * 如未设置，则自动识别，如匹配到当前路由 permission 则 type = permission，否则 type = role
         * @returns {boolean} 是否校验通过
         */
        $auth: function $auth(check, type) {
          var permissions = this.$store.getters['account/permissions'];
          var roles = this.$store.getters['account/roles'];
          var permission = getRoutePermission(permissions, this.$route);
          var role = getRouteRole(roles, this.$route);
          return auth.apply(this, [{
            check: check,
            type: type
          }, permission, role, permissions, roles]);
        }
      }
    });
  }
};
export default AuthorityPlugin;