import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import AdminLayout from '@/layouts/AdminLayout';
import Contextmenu from '@/components/menu/Contextmenu';
import PageToggleTransition from '@/components/transition/PageToggleTransition';
import { mapState, mapMutations } from 'vuex';
import { getI18nKey } from '@/utils/routerUtil';
import AKeepAlive from '@/components/cache/AKeepAlive';
import TabsHead from '@/layouts/tabs/TabsHead';
export default {
  name: 'TabsView',
  i18n: require('./i18n'),
  components: {
    TabsHead: TabsHead,
    PageToggleTransition: PageToggleTransition,
    Contextmenu: Contextmenu,
    AdminLayout: AdminLayout,
    AKeepAlive: AKeepAlive
  },
  data: function data() {
    return {
      clearCaches: [],
      pageList: [],
      activePage: '',
      menuVisible: false,
      refreshing: false,
      excludeKeys: []
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('setting', ['multiPage', 'cachePage', 'animate', 'layout', 'pageWidth'])), {}, {
    menuItemList: function menuItemList() {
      return [{
        key: '1',
        icon: 'vertical-right',
        text: this.$t('closeLeft')
      }, {
        key: '2',
        icon: 'vertical-left',
        text: this.$t('closeRight')
      }, {
        key: '3',
        icon: 'close',
        text: this.$t('closeOthers')
      }, {
        key: '4',
        icon: 'sync',
        text: this.$t('refresh')
      }];
    },
    tabsOffset: function tabsOffset() {
      return this.multiPage ? 24 : 0;
    }
  }),
  created: function created() {
    var _this$$router,
        _this$$router$options,
        _this = this;

    this.loadCacheConfig((_this$$router = this.$router) === null || _this$$router === void 0 ? void 0 : (_this$$router$options = _this$$router.options) === null || _this$$router$options === void 0 ? void 0 : _this$$router$options.routes);
    this.loadCachedTabs();
    var route = this.$route;

    if (this.pageList.findIndex(function (item) {
      return item.fullPath === route.fullPath;
    }) === -1) {
      this.pageList.push(this.createPage(route));
    }

    this.activePage = route.fullPath;

    if (this.multiPage) {
      this.$nextTick(function () {
        _this.setCachedKey(route);
      });
      this.addListener();
    }
  },
  mounted: function mounted() {
    this.correctPageMinHeight(-this.tabsOffset);
  },
  beforeDestroy: function beforeDestroy() {
    this.removeListener();
    this.correctPageMinHeight(this.tabsOffset);
  },
  watch: {
    '$router.options.routes': function $routerOptionsRoutes(val) {
      this.excludeKeys = [];
      this.loadCacheConfig(val);
    },
    '$route': function $route(newRoute) {
      var _this2 = this;

      this.activePage = newRoute.fullPath;

      if (!this.multiPage) {
        this.pageList = [this.createPage(newRoute)];
      } else if (this.pageList.findIndex(function (item) {
        return item.fullPath === newRoute.fullPath;
      }) === -1) {
        this.pageList.push(this.createPage(newRoute));
      }

      if (this.multiPage) {
        this.$nextTick(function () {
          _this2.setCachedKey(newRoute);
        });
      }
    },
    'multiPage': function multiPage(newVal) {
      if (!newVal) {
        this.pageList = [this.createPage(this.$route)];
        this.removeListener();
      } else {
        this.addListener();
      }
    },
    tabsOffset: function tabsOffset(newVal, oldVal) {
      this.correctPageMinHeight(oldVal - newVal);
    }
  },
  methods: _objectSpread({
    changePage: function changePage(key) {
      this.activePage = key;
      this.$router.push(key);
    },
    remove: function remove(key, next) {
      if (this.pageList.length === 1) {
        return this.$message.warning(this.$t('warn'));
      } //清除缓存


      var index = this.pageList.findIndex(function (item) {
        return item.fullPath === key;
      });
      this.clearCaches = this.pageList.splice(index, 1).map(function (page) {
        return page.cachedKey;
      });

      if (next) {
        this.$router.push(next);
      } else if (key === this.activePage) {
        index = index >= this.pageList.length ? this.pageList.length - 1 : index;
        this.activePage = this.pageList[index].fullPath;
        this.$router.push(this.activePage);
      }
    },
    refresh: function refresh(key, page) {
      page = page || this.pageList.find(function (item) {
        return item.fullPath === key;
      });
      page.loading = true;
      this.clearCache(page);

      if (key === this.activePage) {
        this.reloadContent(function () {
          return page.loading = false;
        });
      } else {
        // 其实刷新很快，加这个延迟纯粹为了 loading 状态多展示一会儿，让用户感知刷新这一过程
        setTimeout(function () {
          return page.loading = false;
        }, 500);
      }
    },
    onContextmenu: function onContextmenu(pageKey, e) {
      if (pageKey) {
        e.preventDefault();
        e.meta = pageKey;
        this.menuVisible = true;
      }
    },
    onMenuSelect: function onMenuSelect(key, target, pageKey) {
      switch (key) {
        case '1':
          this.closeLeft(pageKey);
          break;

        case '2':
          this.closeRight(pageKey);
          break;

        case '3':
          this.closeOthers(pageKey);
          break;

        case '4':
          this.refresh(pageKey);
          break;

        default:
          break;
      }
    },
    closeOthers: function closeOthers(pageKey) {
      // 清除缓存
      var clearPages = this.pageList.filter(function (item) {
        return item.fullPath !== pageKey && !item.unclose;
      });
      this.clearCaches = clearPages.map(function (item) {
        return item.cachedKey;
      });
      this.pageList = this.pageList.filter(function (item) {
        return !clearPages.includes(item);
      }); // 判断跳转

      if (this.activePage != pageKey) {
        this.activePage = pageKey;
        this.$router.push(this.activePage);
      }
    },
    closeLeft: function closeLeft(pageKey) {
      var _this3 = this;

      var index = this.pageList.findIndex(function (item) {
        return item.fullPath === pageKey;
      }); // 清除缓存

      var clearPages = this.pageList.filter(function (item, i) {
        return i < index && !item.unclose;
      });
      this.clearCaches = clearPages.map(function (item) {
        return item.cachedKey;
      });
      this.pageList = this.pageList.filter(function (item) {
        return !clearPages.includes(item);
      }); // 判断跳转

      if (!this.pageList.find(function (item) {
        return item.fullPath === _this3.activePage;
      })) {
        this.activePage = pageKey;
        this.$router.push(this.activePage);
      }
    },
    closeRight: function closeRight(pageKey) {
      var _this4 = this;

      // 清除缓存
      var index = this.pageList.findIndex(function (item) {
        return item.fullPath === pageKey;
      });
      var clearPages = this.pageList.filter(function (item, i) {
        return i > index && !item.unclose;
      });
      this.clearCaches = clearPages.map(function (item) {
        return item.cachedKey;
      });
      this.pageList = this.pageList.filter(function (item) {
        return !clearPages.includes(item);
      }); // 判断跳转

      if (!this.pageList.find(function (item) {
        return item.fullPath === _this4.activePage;
      })) {
        this.activePage = pageKey;
        this.$router.push(this.activePage);
      }
    },
    clearCache: function clearCache(page) {
      page._init_ = false;
      this.clearCaches = [page.cachedKey];
    },
    reloadContent: function reloadContent(onLoaded) {
      var _this5 = this;

      this.refreshing = true;
      setTimeout(function () {
        _this5.refreshing = false;

        _this5.$nextTick(function () {
          _this5.setCachedKey(_this5.$route);

          if (typeof onLoaded === 'function') {
            onLoaded.apply(_this5, []);
          }
        });
      }, 200);
    },
    pageName: function pageName(page) {
      return this.$t(getI18nKey(page.keyPath));
    },

    /**
     * 添加监听器
     */
    addListener: function addListener() {
      window.addEventListener('page:close', this.closePageListener);
      window.addEventListener('page:refresh', this.refreshPageListener);
      window.addEventListener('unload', this.unloadListener);
    },

    /**
     * 移出监听器
     */
    removeListener: function removeListener() {
      window.removeEventListener('page:close', this.closePageListener);
      window.removeEventListener('page:refresh', this.refreshPageListener);
      window.removeEventListener('unload', this.unloadListener);
    },

    /**
     * 页签关闭事件监听
     * @param event 页签关闭事件
     */
    closePageListener: function closePageListener(event) {
      var _event$detail = event.detail,
          closeRoute = _event$detail.closeRoute,
          nextRoute = _event$detail.nextRoute;
      var closePath = typeof closeRoute === 'string' ? closeRoute : closeRoute.path;
      this.remove(closePath, nextRoute);
    },

    /**
     * 页面刷新事件监听
     * @param event 页签关闭事件
     */
    refreshPageListener: function refreshPageListener(event) {
      var pageKey = event.detail.pageKey;
      this.refresh(pageKey);
    },

    /**
     * 页面 unload 事件监听器，添加页签到 session 缓存，用于刷新时保留页签
     */
    unloadListener: function unloadListener() {
      var tabs = this.pageList.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          _init_: false
        });
      });
      sessionStorage.setItem(process.env.VUE_APP_TBAS_KEY, JSON.stringify(tabs));
    },
    createPage: function createPage(route) {
      return {
        keyPath: route.matched[route.matched.length - 1].path,
        fullPath: route.fullPath,
        loading: false,
        title: route.meta && route.meta.page && route.meta.page.title,
        unclose: route.meta && route.meta.page && route.meta.page.closable === false
      };
    },

    /**
     * 设置页面缓存的key
     * @param route 页面对应的路由
     */
    setCachedKey: function setCachedKey(route) {
      var page = this.pageList.find(function (item) {
        return item.fullPath === route.fullPath;
      });
      page.unclose = route.meta && route.meta.page && route.meta.page.closable === false;

      if (!page._init_) {
        var vnode = this.$refs.tabContent.$vnode;
        page.cachedKey = vnode.key + vnode.componentOptions.Ctor.cid;
        page._init_ = true;
      }
    },

    /**
     * 加载缓存的 tabs
     */
    loadCachedTabs: function loadCachedTabs() {
      var cachedTabsStr = sessionStorage.getItem(process.env.VUE_APP_TBAS_KEY);

      if (cachedTabsStr) {
        try {
          var cachedTabs = JSON.parse(cachedTabsStr);

          if (cachedTabs.length > 0) {
            this.pageList = cachedTabs;
          }
        } catch (e) {} finally {
          sessionStorage.removeItem(process.env.VUE_APP_TBAS_KEY);
        }
      }
    },
    loadCacheConfig: function loadCacheConfig(routes) {
      var _this6 = this;

      var pCache = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      routes.forEach(function (item) {
        var _ref, _item$meta$page$cache, _item$meta, _item$meta$page;

        var cacheAble = (_ref = (_item$meta$page$cache = (_item$meta = item.meta) === null || _item$meta === void 0 ? void 0 : (_item$meta$page = _item$meta.page) === null || _item$meta$page === void 0 ? void 0 : _item$meta$page.cacheAble) !== null && _item$meta$page$cache !== void 0 ? _item$meta$page$cache : pCache) !== null && _ref !== void 0 ? _ref : true;

        if (!cacheAble) {
          _this6.excludeKeys.push(new RegExp("".concat(item.fullPath, "\\d+$")));
        }

        if (item.children) {
          _this6.loadCacheConfig(item.children, cacheAble);
        }
      });
    }
  }, mapMutations('setting', ['correctPageMinHeight']))
};