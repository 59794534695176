var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-menu', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "contextmenu",
    style: _vm.style,
    attrs: {
      "selectedKeys": _vm.selectedKeys
    },
    on: {
      "click": _vm.handleClick
    }
  }, _vm._l(_vm.itemList, function (item) {
    return _c('a-menu-item', {
      key: item.key
    }, [item.icon ? _c('a-icon', {
      attrs: {
        "type": item.icon
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(item.text))])], 1);
  }), 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };