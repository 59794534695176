import _objectSpread from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('div', {
    staticClass: "side-setting"
  }, [_c('setting-item', [_c('a-button', {
    attrs: {
      "type": "primary",
      "icon": "save"
    },
    on: {
      "click": _vm.saveSetting
    }
  }, [_vm._v(_vm._s(_vm.$t('save')))]), _c('a-button', {
    staticStyle: {
      "float": "right"
    },
    attrs: {
      "type": "dashed",
      "icon": "redo"
    },
    on: {
      "click": _vm.resetSetting
    }
  }, [_vm._v(_vm._s(_vm.$t('reset')))])], 1), _c('setting-item', {
    attrs: {
      "title": _vm.$t('theme.title')
    }
  }, [_c('img-checkbox-group', {
    attrs: {
      "default-values": [_vm.theme.mode]
    },
    on: {
      "change": function change(values) {
        return _vm.setTheme(_objectSpread(_objectSpread({}, _vm.theme), {}, {
          mode: values[0]
        }));
      }
    }
  }, [_c('img-checkbox', {
    attrs: {
      "title": _vm.$t('theme.dark'),
      "img": "https://gw.alipayobjects.com/zos/rmsportal/LCkqqYNmvBEbokSDscrm.svg",
      "value": "dark"
    }
  }), _c('img-checkbox', {
    attrs: {
      "title": _vm.$t('theme.light'),
      "img": "https://gw.alipayobjects.com/zos/rmsportal/jpRkZQMyYRryryPNtyIC.svg",
      "value": "light"
    }
  }), _c('img-checkbox', {
    attrs: {
      "title": _vm.$t('theme.night'),
      "img": "https://gw.alipayobjects.com/zos/antfincdn/hmKaLQvmY2/LCkqqYNmvBEbokSDscrm.svg",
      "value": "night"
    }
  })], 1)], 1), _c('setting-item', {
    attrs: {
      "title": _vm.$t('theme.color')
    }
  }, [_c('color-checkbox-group', {
    attrs: {
      "defaultValues": [_vm.palettes.indexOf(_vm.theme.color)],
      "multiple": false
    },
    on: {
      "change": function change(values, colors) {
        return _vm.setTheme(_objectSpread(_objectSpread({}, _vm.theme), {}, {
          color: colors[0]
        }));
      }
    }
  }, _vm._l(_vm.palettes, function (color, index) {
    return _c('color-checkbox', {
      key: index,
      attrs: {
        "color": color,
        "value": index
      }
    });
  }), 1)], 1), _c('a-divider'), _c('setting-item', {
    attrs: {
      "title": _vm.$t('navigate.title')
    }
  }, [_c('img-checkbox-group', {
    attrs: {
      "default-values": [_vm.layout]
    },
    on: {
      "change": function change(values) {
        return _vm.setLayout(values[0]);
      }
    }
  }, [_c('img-checkbox', {
    attrs: {
      "title": _vm.$t('navigate.side'),
      "img": "https://gw.alipayobjects.com/zos/rmsportal/JopDzEhOqwOjeNTXkoje.svg",
      "value": "side"
    }
  }), _c('img-checkbox', {
    attrs: {
      "title": _vm.$t('navigate.head'),
      "img": "https://gw.alipayobjects.com/zos/rmsportal/KDNDBbriJhLwuqMoxcAr.svg",
      "value": "head"
    }
  }), _c('img-checkbox', {
    attrs: {
      "title": _vm.$t('navigate.mix'),
      "img": "https://gw.alipayobjects.com/zos/antfincdn/x8Ob%26B8cy8/LCkqqYNmvBEbokSDscrm.svg",
      "value": "mix"
    }
  })], 1)], 1), _c('setting-item', [_c('a-list', {
    attrs: {
      "split": false
    }
  }, [_c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('navigate.content.title')) + " "), _c('a-select', {
    staticClass: "select-item",
    attrs: {
      "slot": "actions",
      "getPopupContainer": _vm.getPopupContainer,
      "value": _vm.pageWidth,
      "size": "small"
    },
    on: {
      "change": _vm.setPageWidth
    },
    slot: "actions"
  }, [_c('a-select-option', {
    attrs: {
      "value": "fluid"
    }
  }, [_vm._v(_vm._s(_vm.$t('navigate.content.fluid')))]), _c('a-select-option', {
    attrs: {
      "value": "fixed"
    }
  }, [_vm._v(_vm._s(_vm.$t('navigate.content.fixed')))])], 1)], 1), _c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('navigate.fixedHeader')) + " "), _c('a-switch', {
    attrs: {
      "slot": "actions",
      "checked": _vm.fixedHeader,
      "size": "small"
    },
    on: {
      "change": _vm.setFixedHeader
    },
    slot: "actions"
  })], 1), _c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('navigate.fixedSideBar')) + " "), _c('a-switch', {
    attrs: {
      "slot": "actions",
      "checked": _vm.fixedSideBar,
      "size": "small"
    },
    on: {
      "change": _vm.setFixedSideBar
    },
    slot: "actions"
  })], 1)], 1)], 1), _c('a-divider'), _c('setting-item', {
    attrs: {
      "title": _vm.$t('other.title')
    }
  }, [_c('a-list', {
    attrs: {
      "split": false
    }
  }, [_c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('other.weekMode')) + " "), _c('a-switch', {
    attrs: {
      "slot": "actions",
      "checked": _vm.weekMode,
      "size": "small"
    },
    on: {
      "change": _vm.setWeekMode
    },
    slot: "actions"
  })], 1), _c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('other.multiPages')) + " "), _c('a-switch', {
    attrs: {
      "slot": "actions",
      "checked": _vm.multiPage,
      "size": "small"
    },
    on: {
      "change": _vm.setMultiPage
    },
    slot: "actions"
  })], 1), _c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('other.hideSetting')) + " "), _c('a-switch', {
    attrs: {
      "slot": "actions",
      "checked": _vm.hideSetting,
      "size": "small"
    },
    on: {
      "change": _vm.setHideSetting
    },
    slot: "actions"
  })], 1)], 1)], 1), _c('a-divider'), _c('setting-item', {
    attrs: {
      "title": _vm.$t('animate.title')
    }
  }, [_c('a-list', {
    attrs: {
      "split": false
    }
  }, [_c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('animate.disable')) + " "), _c('a-switch', {
    attrs: {
      "slot": "actions",
      "checked": _vm.animate.disabled,
      "size": "small"
    },
    on: {
      "change": function change(val) {
        return _vm.setAnimate(_objectSpread(_objectSpread({}, _vm.animate), {}, {
          disabled: val
        }));
      }
    },
    slot: "actions"
  })], 1), _c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('animate.effect')) + " "), _c('a-select', {
    staticClass: "select-item",
    attrs: {
      "slot": "actions",
      "value": _vm.animate.name,
      "getPopupContainer": _vm.getPopupContainer,
      "size": "small"
    },
    on: {
      "change": function change(val) {
        return _vm.setAnimate(_objectSpread(_objectSpread({}, _vm.animate), {}, {
          name: val
        }));
      }
    },
    slot: "actions"
  }, _vm._l(_vm.animates, function (item, index) {
    return _c('a-select-option', {
      key: index,
      attrs: {
        "value": item.name
      }
    }, [_vm._v(_vm._s(item.alias))]);
  }), 1)], 1), _c('a-list-item', [_vm._v(" " + _vm._s(_vm.$t('animate.direction')) + " "), _c('a-select', {
    staticClass: "select-item",
    attrs: {
      "slot": "actions",
      "value": _vm.animate.direction,
      "getPopupContainer": _vm.getPopupContainer,
      "size": "small"
    },
    on: {
      "change": function change(val) {
        return _vm.setAnimate(_objectSpread(_objectSpread({}, _vm.animate), {}, {
          direction: val
        }));
      }
    },
    slot: "actions"
  }, _vm._l(_vm.directions, function (item, index) {
    return _c('a-select-option', {
      key: index,
      attrs: {
        "value": item
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1)], 1)], 1)], 1), _vm.isDev ? _c('a-alert', {
    staticStyle: {
      "max-width": "240px",
      "margin": "-16px 0 8px",
      "word-break": "break-all"
    },
    attrs: {
      "type": "warning",
      "message": _vm.$t('alert')
    }
  }) : _vm._e(), _vm.isDev ? _c('a-button', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "copyBtn",
      "data-clipboard-text": _vm.copyConfig,
      "icon": "copy"
    },
    on: {
      "click": _vm.copyCode
    }
  }, [_vm._v(_vm._s(_vm.$t('copy')))]) : _vm._e()], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };