import "core-js/modules/es.function.name.js";

var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-dropdown', [_c('div', {
    staticClass: "header-avatar",
    staticStyle: {
      "cursor": "pointer"
    }
  }, [_c('a-avatar', {
    staticClass: "avatar",
    attrs: {
      "size": "small",
      "shape": "circle",
      "icon": "user",
      "src": _vm.user.avatar
    }
  }), _c('span', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.user.name))])], 1), _c('a-menu', {
    class: ['avatar-menu'],
    attrs: {
      "slot": "overlay"
    },
    slot: "overlay"
  }, [_c('a-menu-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('a-icon', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "type": "poweroff"
    }
  }), _c('span', [_vm._v("退出登录")])], 1)], 1)], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };