export default {
  name: 'HeaderNotice',
  data: function data() {
    return {
      loading: false,
      show: false
    };
  },
  computed: {},
  methods: {
    fetchNotice: function fetchNotice() {
      var _this = this;

      if (this.loading) {
        this.loading = false;
        return;
      }

      this.loadding = true;
      setTimeout(function () {
        _this.loadding = false;
      }, 1000);
    }
  }
};