var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-config-provider', {
    attrs: {
      "locale": _vm.locale,
      "get-popup-container": _vm.popContainer
    }
  }, [_c('router-view')], 1);
};

var staticRenderFns = [];
export { render, staticRenderFns };