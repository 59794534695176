var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c('a-dropdown', {
    attrs: {
      "trigger": ['click']
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('div', {
    attrs: {
      "slot": "overlay"
    },
    slot: "overlay"
  }, [_c('a-spin', {
    attrs: {
      "spinning": _vm.loading
    }
  }, [_c('a-tabs', {
    staticClass: "dropdown-tabs",
    style: {
      width: '297px'
    },
    attrs: {
      "tabBarStyle": {
        textAlign: 'center'
      }
    }
  }, [_c('a-tab-pane', {
    key: "1",
    attrs: {
      "tab": "通知"
    }
  }, [_c('a-list', {
    staticClass: "tab-pane"
  }, [_c('a-list-item', [_c('a-list-item-meta', {
    attrs: {
      "title": "你收到了 14 份新周报",
      "description": "一年前"
    }
  }, [_c('a-avatar', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "slot": "avatar",
      "src": "https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"
    },
    slot: "avatar"
  })], 1)], 1), _c('a-list-item', [_c('a-list-item-meta', {
    attrs: {
      "title": "你推荐的 曲妮妮 已通过第三轮面试",
      "description": "一年前"
    }
  }, [_c('a-avatar', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "slot": "avatar",
      "src": "https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png"
    },
    slot: "avatar"
  })], 1)], 1), _c('a-list-item', [_c('a-list-item-meta', {
    attrs: {
      "title": "这种模板可以区分多种通知类型",
      "description": "一年前"
    }
  }, [_c('a-avatar', {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      "slot": "avatar",
      "src": "https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png"
    },
    slot: "avatar"
  })], 1)], 1)], 1)], 1), _c('a-tab-pane', {
    key: "2",
    attrs: {
      "tab": "消息"
    }
  }, [_c('a-list', {
    staticClass: "tab-pane"
  })], 1), _c('a-tab-pane', {
    key: "3",
    attrs: {
      "tab": "待办"
    }
  }, [_c('a-list', {
    staticClass: "tab-pane"
  })], 1)], 1)], 1)], 1), _c('span', {
    staticClass: "header-notice",
    on: {
      "click": _vm.fetchNotice
    }
  }, [_c('a-badge', {
    staticClass: "notice-badge",
    attrs: {
      "count": "12"
    }
  }, [_c('a-icon', {
    class: ['header-notice-icon'],
    attrs: {
      "type": "bell"
    }
  })], 1)], 1)]);
};

var staticRenderFns = [];
export { render, staticRenderFns };