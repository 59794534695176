import _createForOfIteratorHelper from "G:/worker/DeviceCloud/vue-antd-admin/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { isDef, isRegExp, remove } from '@/utils/util';
var patternTypes = [String, RegExp, Array];

function matches(pattern, name) {
  if (Array.isArray(pattern)) {
    if (pattern.indexOf(name) > -1) {
      return true;
    } else {
      var _iterator = _createForOfIteratorHelper(pattern),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (isRegExp(item) && item.test(name)) {
            return true;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return false;
    }
  } else if (typeof pattern === 'string') {
    return pattern.split(',').indexOf(name) > -1;
  } else if (isRegExp(pattern)) {
    return pattern.test(name);
  }
  /* istanbul ignore next */


  return false;
}

function getComponentName(opts) {
  return opts && (opts.Ctor.options.name || opts.tag);
}

function getComponentKey(vnode) {
  var componentOptions = vnode.componentOptions,
      key = vnode.key;
  return key == null ? componentOptions.Ctor.cid + (componentOptions.tag ? "::".concat(componentOptions.tag) : '') : key + componentOptions.Ctor.cid;
}

function getFirstComponentChild(children) {
  if (Array.isArray(children)) {
    for (var i = 0; i < children.length; i++) {
      var c = children[i];

      if (isDef(c) && (isDef(c.componentOptions) || c.isAsyncPlaceholder)) {
        return c;
      }
    }
  }
}

function pruneCache(keepAliveInstance, filter) {
  var cache = keepAliveInstance.cache,
      keys = keepAliveInstance.keys,
      _vnode = keepAliveInstance._vnode;

  for (var key in cache) {
    var cachedNode = cache[key];

    if (cachedNode) {
      var name = getComponentName(cachedNode.componentOptions);
      var componentKey = getComponentKey(cachedNode);

      if (name && !filter(name, componentKey)) {
        pruneCacheEntry(cache, key, keys, _vnode);
      }
    }
  }
}

function pruneCacheEntry2(cache, key, keys) {
  var cached = cache[key];

  if (cached) {
    cached.componentInstance.$destroy();
  }

  cache[key] = null;
  remove(keys, key);
}

function pruneCacheEntry(cache, key, keys, current) {
  var cached = cache[key];

  if (cached && (!current || cached.tag !== current.tag)) {
    cached.componentInstance.$destroy();
  }

  cache[key] = null;
  remove(keys, key);
}

export default {
  name: 'AKeepAlive',
  abstract: true,
  model: {
    prop: 'clearCaches',
    event: 'clear'
  },
  props: {
    include: patternTypes,
    exclude: patternTypes,
    excludeKeys: patternTypes,
    max: [String, Number],
    clearCaches: Array
  },
  watch: {
    clearCaches: function clearCaches(val) {
      if (val && val.length > 0) {
        var cache = this.cache,
            keys = this.keys;
        val.forEach(function (key) {
          pruneCacheEntry2(cache, key, keys);
        });
        this.$emit('clear', []);
      }
    }
  },
  created: function created() {
    this.cache = Object.create(null);
    this.keys = [];
  },
  destroyed: function destroyed() {
    for (var key in this.cache) {
      pruneCacheEntry(this.cache, key, this.keys);
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$watch('include', function (val) {
      pruneCache(_this, function (name) {
        return matches(val, name);
      });
    });
    this.$watch('exclude', function (val) {
      pruneCache(_this, function (name) {
        return !matches(val, name);
      });
    });
    this.$watch('excludeKeys', function (val) {
      pruneCache(_this, function (name, key) {
        return !matches(val, key);
      });
    });
  },
  render: function render() {
    var slot = this.$slots.default;
    var vnode = getFirstComponentChild(slot);
    var componentOptions = vnode && vnode.componentOptions;

    if (componentOptions) {
      // check pattern
      var name = getComponentName(componentOptions);
      var componentKey = getComponentKey(vnode);
      var include = this.include,
          exclude = this.exclude,
          excludeKeys = this.excludeKeys;

      if ( // not included
      include && (!name || !matches(include, name)) || // excluded
      exclude && name && matches(exclude, name) || excludeKeys && componentKey && matches(excludeKeys, componentKey)) {
        return vnode;
      }

      var cache = this.cache,
          keys = this.keys;
      var key = vnode.key == null // same constructor may get registered as different local components
      // so cid alone is not enough (#3269)
      ? componentOptions.Ctor.cid + (componentOptions.tag ? "::".concat(componentOptions.tag) : '') : vnode.key + componentOptions.Ctor.cid;

      if (cache[key]) {
        vnode.componentInstance = cache[key].componentInstance; // make current key freshest

        remove(keys, key);
        keys.push(key);
      } else {
        cache[key] = vnode;
        keys.push(key); // prune oldest entry

        if (this.max && keys.length > parseInt(this.max)) {
          pruneCacheEntry(cache, keys[0], keys, this._vnode);
        }
      }

      vnode.data.keepAlive = true;
    }

    return vnode || slot && slot[0];
  }
};