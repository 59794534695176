<template>
  <!-- <a-layout-header :class="[headerTheme, 'admin-header']"> -->
  <a-layout-header
    :class="isAdmin ? 'hdbackgroundImage' : 'hdbackgroundImage_user'"
    class="admin-header"
  >
    <div :class="['admin-header-wide', layout, pageWidth]">
      <!-- <router-link v-if="isMobile || layout === 'head'" to="/" :class="['logo', isMobile ? null : 'pc', headerTheme]"> -->
      <div class="admin-header-daterange">
        <a-range-picker
          v-if="isAdmin || viewDetail"
          v-model="time2"
          :ranges="{
            今天: [moment(), moment()],
            本周: [moment().startOf('week'), moment()],
            本月: [moment().startOf('month'), moment()],
            今年: [moment().startOf('year'), moment()],
            '七天(默认)': [moment().add(-7, 'days'), moment()],
            三十天: [moment().add(-30, 'days'), moment()],
            所有: [moment('2000-01-01'), moment()],
          }"
          @change="onChange"
          @ok="onOk"
          :disabled-date="disabledDate"
        >
          <span>
            <span>时间：</span>
            <span class="admin-header-daterange_display">
              {{ vtime2.split("|")[0] }}<span>至</span>{{ vtime2.split("|")[1]
              }}<span><a-icon type="calendar" theme="filled" /></span
            ></span>
            <a-tooltip>
              <template slot="title">
                时间选择未包含今天，数据可能不是最新数据
              </template>
              <span v-if="!isRealTime" class="admin-header-daterange_alert"
                >非实时</span
              >
            </a-tooltip>
          </span>
        </a-range-picker>
        <span v-else>
          {{ vtimen }}
        </span>
      </div>
      <div class="admin-header-logo" :class="{ ie: ieVersion > 0 }">
        <h1 class="admin-header-logo__title">{{ systemName }}</h1>
        <div class="admin-header-logo_title">
          <div class="admin-header-logo_title_lt"></div>
          <div class="admin-header-logo_title_lt"></div>
          <div class="admin-header-logo_title_lt"></div>
        </div>
        <svg height="0" width="0">
          <defs>
            <clipPath id="svgTextPath">
              <text x="10" y="42" textLength="500px">{{ systemName }}</text>
            </clipPath>
          </defs>
        </svg>
      </div>
      <!-- <router-link to="/" :class="['logo', 'pc', headerTheme]"> -->
      <!-- <img width="32" src="@/assets/img/logo.png" /> -->
      <!-- <h1 v-if="!isMobile">{{systemName}}</h1> -->
      <!-- <h1>{{systemName}}</h1> -->
      <!-- </router-link> -->
      <!-- <a-divider v-if="isMobile" type="vertical" /> -->
      <!-- <a-icon v-if="layout !== 'head'" class="trigger" :type="collapsed ? 'menu-unfold' : 'menu-fold'" @click="toggleCollapse"/> -->
      <!-- <div v-if="layout !== 'side' && !isMobile" class="admin-header-menu" :style="`width: ${menuWidth};`"> -->
      <!-- <div class="admin-header-menu" :style="`width: ${menuWidth};`"> -->
      <div class="admin-header-menu">
        <i-menu
          class="head-menu"
          :theme="headerTheme"
          mode="horizontal"
          :options="menuData"
          @select="onSelect"
        />
      </div>
      <div :class="['admin-header-right', headerTheme]">
        <!-- <header-search class="header-item" @active="val => searchActive = val" />
          <a-tooltip class="header-item" title="帮助文档" placement="bottom" >
            <a href="https://iczer.gitee.io/vue-antd-admin-docs/" target="_blank">
              <a-icon type="question-circle-o" />
            </a>
          </a-tooltip>
          <header-notice class="header-item"/> -->
        <span v-if="!isAdmin" class="admin-header-right-name"
          >欢迎您，{{ user.username }}</span
        >
        <a-button v-if="isAdmin" type="link" @click="clickFullScreen">
          <a-icon v-if="isFullscreenEnabled" type="fullscreen-exit" />
          <a-icon v-else type="fullscreen" />
        </a-button>
        <a-button v-if="!isAdmin" type="link" @click="clickChangePsw"
          >修改密码</a-button
        >
        <a-button
          type="link"
          @click="
            () => {
              $emit('logout');
            }
          "
          >退出</a-button
        >
        <!-- <header-avatar class="header-item" /> -->
        <!-- <a-dropdown class="lang header-item">
            <div>
              <a-icon type="global"/> {{langAlias}}
            </div>
            <a-menu @click="val => setLang(val.key)" :selected-keys="[lang]" slot="overlay">
              <a-menu-item v-for=" lang in langList" :key="lang.key">{{lang.key.toLowerCase() + ' ' + lang.name}}</a-menu-item>
            </a-menu>
          </a-dropdown> -->
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import HeaderSearch from "./HeaderSearch";
import HeaderNotice from "./HeaderNotice";
import HeaderAvatar from "./HeaderAvatar";
import IMenu from "@/components/menu/menu";
import { mapState, mapMutations, mapGetters } from "vuex";
import hbackground from "../../assets/img/header.png";
import { format } from "date-fns";
// eslint-disable-next-line no-unused-vars
import { logout } from "@/services/user";
// eslint-disable-next-line no-unused-vars
import moment from "moment";

export default {
  name: "AdminHeader",
  // eslint-disable-next-line vue/no-unused-components
  components: { IMenu, HeaderAvatar, HeaderNotice, HeaderSearch },
  props: ["collapsed", "menuData"],
  data() {
    return {
      langList: [
        { key: "CN", name: "简体中文", alias: "简体" },
        { key: "HK", name: "繁體中文", alias: "繁體" },
        { key: "US", name: "English", alias: "English" },
      ],
      searchActive: false,
      TickTimer1: null,
      time2: undefined,
      etime: undefined,
      etimeTick: 0,
      vtime2: "",
      vtimen: format(
        // new Date(new Date().getTime()),
        Date.now(),
        "yyyy年MM月dd日 HH:mm:ss"
      ),
      hbackground,
      isAdmin: true,
      viewDetail: false,
    };
  },
  computed: {
    ...mapState("setting", [
      "theme",
      "isMobile",
      "layout",
      "systemName",
      "lang",
      "pageWidth",
      "ieVersion",
    ]),
    ...mapState("date", ["timeRange", "isRealTime"]),
    ...mapGetters("account", ["roles", "user"]),
    ...mapGetters("setting", ["appVersion"]),
    headerTheme() {
      if (
        this.layout == "side" &&
        this.theme.mode == "dark" &&
        !this.isMobile
      ) {
        return "light";
      }
      return this.theme.mode;
    },
    langAlias() {
      let lang = this.langList.find((item) => item.key == this.lang);
      return lang.alias;
    },
    menuWidth() {
      const { layout, searchActive } = this;
      const headWidth = layout === "head" ? "100% - 188px" : "100%";
      const extraWidth = searchActive ? "600px" : "400px";
      return `calc(${headWidth} - ${extraWidth})`;
    },
    isFullscreenEnabled() {
      return (
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled ||
        false
      );
    },
    defaultTimeRange() {
      let time1 = format(
        new Date(Date.now() - 1000 * 7 * 60 * 60 * 24),
        "yyyy-MM-dd"
      );
      return [moment(time1).startOf("day"), moment().endOf("day")];
    },
  },
  watch: {
    $route(v) {
      // this.setHtmlTitle();
      // console.log('ah watch', v);
      if (v.path === "/devices/detail") {
        this.viewDetail = true;
      } else {
        this.viewDetail = false;
      }
    },
  },
  mounted() {
    if (this.roles.id !== "admin") {
      this.isAdmin = false;
    }
    this.vtime2 = `${this.timeRange[0].substr(
      0,
      10
    )}|${this.timeRange[1].substr(0, 10)}`;
    this.time2 = [moment(this.timeRange[0]), moment()];
    this.TickTimer1 = setInterval(this.updateTime, 1000);
    this.etime = this.timeRange[1].substr(0, 10);
    this.checkVersion();
  },
  beforeDestroy() {
    if (this.TickTimer1) {
      clearInterval(this.TickTimer1);
      this.TickTimer1 = null;
    }
  },
  methods: {
    moment,
    fullScreen(el) {
      var isFullscreen =
        document.fullScreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      if (!isFullscreen) {
        //进入全屏,多重短路表达式
        (el.requestFullscreen && el.requestFullscreen()) ||
          (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
          (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) ||
          (el.msRequestFullscreen && el.msRequestFullscreen());
      } else {
        //退出全屏,三目运算符
        document.exitFullscreen
          ? document.exitFullscreen()
          : document.mozCancelFullScreen
          ? document.mozCancelFullScreen()
          : document.webkitExitFullscreen
          ? document.webkitExitFullscreen()
          : "";
      }
    },
    clickFullScreen() {
      this.fullScreen(document.documentElement);
    },
    clickChangePsw() {
      this.$emit("changePSW");
    },
    toggleCollapse() {
      this.$emit("toggleCollapse");
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    onChange(value, dateString) {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
      console.log("Formatted Selected time2: ", this.time2);
      let timeN = format(Date.now(), "yyyy-MM-dd");
      let timeAfterNow = moment(dateString[1]).isSameOrAfter(timeN);
      this.setIsRealTime(timeAfterNow);
      this.vtime2 = `${dateString[0]}|${dateString[1]}`;
      this.etime = dateString[1];
      let stime = dateString[0] + " 00:00:00";
      let etime = dateString[1] + " 23:59:59";

      let tr = [stime, etime];
      console.log("Formatted Selected tr: ", tr);
      this.setTimeRange(tr);
    },
    updateTime() {
      this.vtimen = format(Date.now(), "yyyy年MM月dd日 HH:mm:ss");
      this.etimeTick++;
      if (this.etimeTick > 59) {
        this.etimeTick = 0;
        if (this.isRealTime) {
          let time = format(Date.now(), "yyyy-MM-dd");
          console.log("timeRange auto: ", time, this.etime);
          if (time != this.etime) {
            this.setIsRealTime(false);
            // let tr1 = this.timeRange[0].substr(0, 10);
            // this.etime = time;
            // this.vtime2 = `${tr1}|${time}`;
            // let tr = [this.timeRange[0], time + " 23:59:59"];
            // this.setTimeRange(tr);
            // this.$notification['info']({
            //   message: '日期已自动变更',
            //   description: '如果时间选择范围不是“非实时”状态，系统会自动在每天零点，跟随系统日期自动调整，并更新数据。',
            //   duration: null
            // })
          }
        }
      }
    },
    onOk(value) {
      console.log("onOk: ", value);
    },
    onSelect(obj) {
      this.$emit("menuSelect", obj);
    },
    checkVersion() {
      // let random = Math.floor(Math.random() * 2);
      if (this.appVersion) {
        console.log("appVersion", this.appVersion);
        let ver = this.appVersion.split(".");
        let pver = process.env.VUE_APP_VERSION.split(".");
        if (pver.length < 3) return;

        if (ver.length < 3 || ver[0] < pver[0] || ver[1] < pver[1]) {
          console.log("appVersion Expected");
          this.$message.error("网页已经过期，请重新登录", 3);
          setTimeout(() => {
            logout();
            this.$router.push("/login");
          }, 1733);
        }
        this.setAppVersion(process.env.VUE_APP_VERSION);
      } else {
        this.setAppVersion(process.env.VUE_APP_VERSION);
      }
    },
    ...mapMutations("setting", ["setLang", "setAppVersion"]),
    ...mapMutations("date", ["setTimeRange", "setIsRealTime"]),
  },
};
</script>

<style lang="less" scoped>
@import "index";
.hdbackgroundImage {
  .admin-header-wide {
    background: url(../../assets/img/header.png) no-repeat;
    background-position: center;
  }
}
.hdbackgroundImage_user {
  .admin-header-wide {
    background: url(../../assets/img/header_user.png) no-repeat;
    background-position: center;
  }
}
.admin-header {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: url(../../assets/img/header_r.png) repeat-x;
  .admin-header-wide {
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 1920px;
  }
  &-daterange {
    position: relative;
    top: 7px;
    margin-left: 0;
    width: 400px;
    height: 64px;
    :deep(.ant-calendar-picker) {
      color: #fff;
      top: 2px;
      cursor: pointer;
    }
    &_display {
      border: 1px solid #1e92c2;
      padding: 2px 5px 2px 2px;
      border-radius: 5px;
      span {
        color: #31a9f7;
      }
      span:nth-child(1) {
        margin: 0 5px;
      }
      span:last-child {
        margin-left: 5px;
      }
    }
    &_alert {
      border: 1px solid red;
      padding: 2px 5px 2px;
      border-radius: 5px;
      margin-left: 5px;
      background: #4b2b50;
    }
  }
  &-logo {
    margin-left: 95px;
    margin-right: 95px;
    position: relative;
    width: 520px;
    height: 64px;
    // display: inline-block;
    // text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    &__title {
      position: absolute;
      width: 520px;
      height: 60px;
      top: -5px;
      left: 3px;
      z-index: 108;
      letter-spacing: 4px;
      font-size: 42px;
      font-family: Alibaba PuHuiTi;
      text-transform: uppercase;
      font-weight: bold;
      text-shadow: 3px 4px 2px rgba(0, 0, 0, 0.47);
      user-select: none;
      // color: linear-gradient(0deg, #51e0f7 0%, #0863f7 100%);
      // margin-bottom: 0;
      // background-image: linear-gradient(0deg, #51e0f7 0%, #0863f7 100%);
      // background-clip: text;
      // -webkit-text-fill-color: transparent;
    }
    &_title {
      width: 520px;
      height: 60px;
      z-index: 110;
      // font-size: 4rem;
      font-weight: bold;
      text-transform: uppercase;
      position: relative;
      overflow: hidden;
      clip-path: url(#svgTextPath);
      color: white;
      background: linear-gradient(0deg, #51e0f7 0%, #0863f7 100%);
      &_lt {
        width: 20px;
        height: 120px;
        position: absolute;
        top: -20px;
        // left: 50px;
        background: #fffe;
        box-shadow: 0 0 5px 5px #fffa;
        transform: rotate(30deg);
        animation: searchLights 6s ease-in 1s infinite;
      }
      &_lt:nth-child(1) {
        width: 10px;
        animation: searchLights 6s ease-in 1.6s infinite;
      }
      &_lt:nth-child(2) {
        background: #fffd;
        box-shadow: 0 0 5px 8px #fff9;
        animation: searchLights 6s ease-in 3.2s infinite;
      }
    }
    text {
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      font-size: 42px;
      text-transform: uppercase;
    }
    &.ie &__title {
      color: #177df7;
    }
    &.ie &_title {
      display: none;
    }
    // &::before {
    //   // content: "";
    //   // position: absolute;
    //   // width: 20px;
    //   // height: 100px;
    //   // background-color: red;
    //   content: "";
    //   position: absolute;
    //   width: 150px;
    //   height: 10px;
    //   background-color: rgba(255, 255, 255, 0.1);
    //   border: 1px solid red;
    //   filter: grayscale(100%);
    //   transform: rotate(-45deg);
    //   animation: searchLights 6s ease-in 1s infinite;
    // }
  }
  @keyframes searchLights {
    0% {
      left: -90px;
    }
    to {
      left: 100%;
    }
  }
  &-menu {
    position: relative;
    width: 400px;
    top: 7px;
    user-select: none;
    :deep(.ant-menu-horizontal) {
      border-bottom: none;
      > .ant-menu-item:hover,
      > .ant-menu-submenu:hover,
      > .ant-menu-item-active,
      > .ant-menu-submenu-active,
      > .ant-menu-item-open,
      > .ant-menu-submenu-open,
      > .ant-menu-item-selected,
      > .ant-menu-submenu-selected {
        border-bottom: none;
        color: #fff;
        > a {
          color: #fff;
        }
      }
      > a {
        color: #fdfdfd66;
      }
      > .ant-menu-item {
        border-bottom: none;
        // margin-left: 7px;
        min-width: 96px;
        text-align: center;
        > a {
          color: #fdfdfd66;
        }
        &-selected {
          border-bottom: none;
          color: #fff;
          > a {
            color: #fff;
          }
        }
        > a::before {
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }

    :deep(.ant-menu-dark) {
      .ant-menu-item-selected {
        background-color: rgba(255, 255, 255, 0);
      }
    }
  }
  &-right {
    margin-left: 0;
    :deep(.ant-avatar) {
      background: #00245b;
      border: 1px solid #fff;
    }
    &-name {
      line-height: 32px;
      color: #fff;
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 32px;
    }
  }
}
</style>
